import React from "react";

import PageHeader from "../../components/PageHeader";
import PageFooter from "../../components/PageFooter";

import './styles.css'

import { Link } from "react-router-dom";
import Whatsapp from "../../components/Whatsapp";

import Whoweare from "../../assets/whoweare.jpg";
import Banner1 from "../../assets/banner1.png";
import Banner2 from "../../assets/banner2.png";
import Banner3 from "../../assets/banner3.png";
import Ad1 from "../../assets/ad1.png";
import Ad2 from "../../assets/ad2.png";
import Ad4 from "../../assets/ad4.png";


function Home() {
    
    const Adverts = [Ad1, Ad2, Ad4]

    return (
        <div id="page-home">
            <PageHeader />
            <main>

                <div className="slider">
                    {
                        Adverts.map((bannerImg, index) => {
                            return(
                                <div className='slider-holder' key={index}>
                                    <img src={bannerImg} alt="" />
                                </div>
                            )
                        })
                    }
                </div>

                <section className="nav-banners">
                    <h1>PRODUTOS</h1>

                    <div className="nav-holder">
                        <Link to="/olenka" >
                            <img src={Banner1} alt="olenka" />
                        </Link>
                        <Link to="/muy_biela" >
                            <img src={Banner2} alt="muy biela" />
                        </Link>
                        <Link to="/lizze" >
                            <img src={Banner3} alt="lizze" />
                        </Link>
                    </div>
                </section>

                <div className="quem-somos" id="quemsomos">

                    <img src={Whoweare} alt="" />

                    <section>
                        <h1>Quem Somos</h1>
                        <p>
                            Somos uma Distribuidora de Cosméticos Profissionais para Salões de Beleza,
                            distribuidores autorizados Olenka, Muy Biela e Lizze,
                            atendendo Sorocaba e Região.
                        </p>

                    </section>
                </div>
            </main>
            <Whatsapp />
            <PageFooter />
        </div>
    )
}

export default Home