import React, { useState } from "react";

import PageHeader from "../../components/PageHeader";
import PageFooter from "../../components/PageFooter";

import './styles.css'
import Produtos from "../../assets/teste";
import { useParams } from "react-router-dom";
import Whatsapp from "../../components/Whatsapp";

interface ProductParams {
    id: string;
    banner: string;
}

function Products() {
    const params = useParams<ProductParams>()

    const Banner = Produtos.find(e => e.banner === params.banner)
    const Produto = Banner?.content.find(e => e.id === params.id)

    const [Benefits, setBenefits] = useState(true)
    const [Services, setServices] = useState(false)
    const [Howtouse, setHowtouse] = useState(false)

    const [Description, setDescription] = useState(`${Produto?.benefits}`)


    function TurnBenefitsOn() {
        setBenefits(true)
        setServices(false)
        setHowtouse(false)

        setDescription(`${Produto?.benefits}`)
    }
    function TurnServicesOn() {
        setBenefits(false)
        setServices(true)
        setHowtouse(false)

        setDescription(`${Produto?.services}`)
    }
    function TurnHowtouseOn() {
        setBenefits(false)
        setServices(false)
        setHowtouse(true)

        setDescription(`${Produto?.howtouse}`)
    }


    return (
        <div id="page-product">
            <PageHeader />
            <main>
                <section>
                    <h1>{Produto?.name.toUpperCase()}</h1>
                    <div className="product-container">
                        <img className="product-image" src={Produto?.imagem} alt="" />
                        <div className="description-container">
                            {
                                params.banner === 'olenka'
                                 ? 
                                 <nav>
                                <button id="beneficios" onClick={TurnBenefitsOn} className={Benefits ? 'nav-button-selected' : 'nav-button'}>BENEFÍCIOS</button>
                                <button id="servicos" onClick={TurnServicesOn} className={Services ? 'nav-button-selected' : 'nav-button'}>SERVIÇOS</button>
                                <button id="modo_de_uso" onClick={TurnHowtouseOn} className={Howtouse ? 'nav-button-selected' : 'nav-button'}>MODO DE USO</button>
                            </nav>
                            :
                            <nav>
                                <button id="beneficios" onClick={TurnBenefitsOn} className={Benefits ? 'nav-button-selected' : 'nav-button'}>DESCRIÇÃO</button>
                            </nav>
                                 
                            }
                            <p>
                                {Description}
                            </p>
                        </div>
                        <a target="_blank" rel="noopener noreferrer" href={`https://api.whatsapp.com/send?phone=551532221110&text=Ol%C3%A1%2C%20tudo%20bem%3F%20eu%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20${Produto?.name}`} className="wpp-button"><strong>COMPRE AQUI</strong></a>
                    </div>
                </section>

            </main>
            <Whatsapp />
            <PageFooter />

        </div>
    )
}

export default Products