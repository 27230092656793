import React from "react";
import "./styles.css";

import WppIcon from "../../assets/wppIcon.png"

function Whatsapp() {
    return (
        <a className="wpp-container" href="https://api.whatsapp.com/send?phone=551532221110&text=Ol%C3%A1%2C%20tudo%20bem%3F%20eu%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20os%20produtos">
            <img src={WppIcon} alt="WhatsApp"/>
        </a>
    )
}

export default Whatsapp