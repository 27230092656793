import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { FiFacebook, FiInstagram } from "react-icons/fi";

import "./styles.css";

function PageFooter() {
    return (
        <footer className='page-footer'>
            <section>
                <h1>Menu</h1>
                <p>
                    <Link to="/">Início</Link>
                    <br />
                    <HashLink to="/#quemsomos">Quem Somos</HashLink>
                    <br />
                    <a target="_blank" rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=551532221110">Fale Conosco</a>
                </p>
            </section>

            <section>
                <h1>Produtos</h1>
                <p>
                    <Link to='olenka'>Olenka</Link>
                    <br />
                    <Link to='muy_biela'>Muy Biela</Link>
                    <br />
                    <Link to='lizze'>Lizze</Link>
                </p>
            </section>

            <section>
                <h1>Patricinha Cosméticos</h1>
                <p>
                    Copyright 2021 <br />
                    Todos os Direitos Reservados.
                </p>
                <nav>
                    <a className="social-links" href="https://www.facebook.com/Patricinhacosmeticossorocaba">
                        <FiFacebook color="white" fontSize="25" />
                    </a>
                    <a className="social-links" href="https://www.instagram.com/patricinhacosmeticos/">
                        <FiInstagram color="white" fontSize="25" />
                    </a>
                </nav>
            </section>
            
            <section>
                <h1>Contato</h1>
                <p>
                    Email: patricinhacosmeticossorocaba@outlook.com
                    <br />
                    Telefone: (15) 3222-1110
                    <br />
                    Endereço: Avenida Doutor Américo
                    Figueiredo nº 882, Jardim Simus Sorocaba SP.
                    <br />
                    CEP: 18055-132
                    <br />
                    CNPJ: 29.618.280/0001-88
                </p>
            </section>
        </footer>
    )
}

export default PageFooter