import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Estante from './pages/Estante'
import Home from './pages/Home'
import Products from './pages/Produto'

function Routes() {
    return (
        <BrowserRouter>
            <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/:banner" exact component={Estante} />
            <Route path="/:banner/:id" component={Products} />
            </Switch>
        </BrowserRouter>
    )
}

export default Routes