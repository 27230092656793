import React from 'react';
import { useParams } from 'react-router-dom';
import Produtos from '../../assets/teste';
import PageFooter from '../../components/PageFooter';
import PageHeader from '../../components/PageHeader';
import Vitrine from '../../components/Vitrine';
import Whatsapp from '../../components/Whatsapp';
import Banner1 from "../../assets/banner1.png";
import Banner2 from "../../assets/banner2.png";
import Banner3 from "../../assets/banner3.png";

import './styles.css'
import { useState } from 'react';
import { useEffect } from 'react';

interface BannerParams {
    banner: string;
}
function Estante() {

    const params = useParams<BannerParams>()
    const BannerSelected = Produtos.find(e => e.banner === params.banner)
    const Banners = [Banner1, Banner2, Banner3]
    const [imageBanner, setImageBanner] = useState()
    useEffect(() => {
        if (BannerSelected?.banner === 'olenka') {
            return setImageBanner(Banners[0])
        }
        else if (BannerSelected?.banner === 'muy_biela') {
            return setImageBanner(Banners[1])
        }
        else {
            return setImageBanner(Banners[2])
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div id="page-banner">
        <PageHeader/>
        <main>
            <img src={imageBanner} alt="" className="banner-img" />
        <Vitrine banner={BannerSelected?.banner} />
        </main>
        <Whatsapp/>
        <PageFooter/>
        </div>
    );  
}

export default Estante;
//use componentes em vez de usar banco de dados