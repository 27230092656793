import React from "react";
import { Link } from "react-router-dom"
import Produtos from "../../assets/teste"

import './styles.css'

interface BannerParams {
  banner?: string;
}

function Vitrine(props: BannerParams) {

  const PatricinhaData = Produtos.find(e => e.banner === props.banner)
  const Items = PatricinhaData?.content

  const title = PatricinhaData?.banner.toUpperCase().replace(/_/g, ' ')

  return (

    <section className="product-session">
      <h1>PRODUTOS {title}</h1>
      <div className="vitrine">
        {Items?.map(produto => {
          return (
            <Link key={produto.id} to={`/${props.banner}/${produto.id}`} className="produto">
              <div></div>
              <div>
                <img className="foto" src={produto.imagem} alt="" />
              </div>
              <strong>{produto.name.length > 16 ? produto.name.substring(0, 16) + '...' : produto.name}</strong>
            </Link>
          )
        })}
      </div>
    </section>
  )
}

export default Vitrine