const Produtos = [
    {
        banner: "olenka",
        content: [
            {
                id: '1',
                name: "BIO RECONSTRUTOR",
                imagem: "https://olenkacosmeticos.com.br/wp-content/uploads/2020/02/mockup_bio-reconstrutor_gloss01_500ml-1024x881.png",
                benefits: "SPRAY DE REGENERAÇÃO: Atua recuperando de dentro pra fora; GLOSS DE REESTRUTURAÇÃO: Atua recuperando de fora para dentro",
                services: "Recuperação capilar intensa S.O.S. para reações químicas adversas, Cauterização e queratinização, Potencialização de reconstruções e hidratações, Rendimento médio: 45 aplicações.",
                howtouse: "Borrife nos cabelos secos ou úmidos o Spray de Regeneração Bio Reconstrutor Olenka por toda a extensão da cabeça, com o auxílio de um pente, seguindo-se da aplicação do Gloss de Reestruturação Bio Reconstrutor Olenka. Aplique o Gloss de Reestruturação Bio Reconstrutor Olenka nos cabelos, do comprimento até as pontas, com o auxílio de um pente, enluve as mechas e espere por 10 minutos. Em seguida, enxágue com água em abundância, escove e pranche os fios. Dica: podem ser adicionados 5ml do Gloss de Reestruturação Bio Reconstrutor Olenka ao recipiente de preparação da coloração ou à máscara de hidratação, para potencializar a cor ou o tratamento."

            },

            {
                id: '2',
                name: "ROYAL BLOND",
                imagem: "https://olenkacosmeticos.com.br/wp-content/uploads/2020/02/mockup_royal-blond_250ml-RTW-1024x881.png",
                benefits: "Pode ser usado junto com descolorações e alisamentos; Termoativação. Não necessita de enxágue; Correção instantânea sem ressecamento; Enriquecido com pigmentos naturais de blueberry e óleo essencial de cálamo",
                services: "Escova matizadora, Correção e finalização de alisamentos e químicas em cabelos loiros.",
                howtouse: "Com os cabelos úmidos, borrife o SPRAY DE CORREÇÃO DA COR LOIROS INTENSOS ROYAL BLOND OLENKA sobre os fios, à distância de 20 cm, distribuindo-o com a ajuda de um pente fino. Faça escovação, que ativará o brilho o toque acetinado, para finalização e otimização dos resultados.​​​​​​​"

            },

            {
                id: '3',
                name: "ALISANTE CAPILAR ROYAL SOFT",
                imagem: "https://olenkacosmeticos.com.br/wp-content/uploads/2020/02/mockup_royal-soft_1kg--1024x880.png",
                benefits: "Graças à sua tecnologia BIO LACASE®, uma enzima extraída do cogumelo Tramete Versicolor, o ALISANTE CAPILAR ROYAL SOFT OLENKA promove o alisamento natural da fibra capilar. Por meio da incorporação de proteínas + 21 aminoácidos à formulação, deixa os fios altamente selados, tratados e hidratados, pois tem a capacidade de fortalecer e proteger a fibra capilar de dentro para fora. Sem formol e derivados; Pode ser usado todos os meses; Recuperação imediata e redução de volume;",
                services: "Escova de curta duração, Redução de volume e blindagem capilar, Reposição de massa e recuperação intensa, Definição e comportamento de cachos, Rendimento médio: 35 aplicações.",
                howtouse: "Após lavar os cabelos com o SHAMPOO DILATADOR ROYAL LOOK OLENKA, seque 50% dos fios com o auxílio de uma toalha e o divida em 4 partes iguais. Aplique, mecha a mecha, o ALISANTE CAPILAR ROYAL SOFT OLENKA, com o auxílio de um pente médio, em seguida, deixa-a agir por 10 a 20 minutos, a depender da estrutura do fio. Após a pausa, enxágue o cabelo com água em abundância.​​​​​​​"

            },

            {
                id: '4',
                name: "BLEND DE PROTEÍNAS",
                imagem: "https://olenkacosmeticos.com.br/wp-content/uploads/2020/11/mockup_blend-de-proteinas_600g-red-min.png",
                benefits: "O Blend de Proteínas Olenka atua diretamente na reposição das proteínas perdidas por processos químicos e físicos, como alisamento, colorações, descolorações e relaxamentos. Sua estrutura composta por 12 das mais potentes proteínas usadas pela cosmetologia, permite a perfeita sinergia entre nutrição e regeneração total dos fios. Cabelos mais fortes com menos quebra; Devolve as proteínas perdidas; Reduz e protege contra a ação das químicas; Protege contra a ação do tempo; Aumenta a resistência dos fios; Cabelos mais fáceis de pentear.",
                services: "Tratamento de alta performance sem deixar os cabelos pesados;Fácil desembaraçamento; Cabelos mais fortes, resistentes e com menos quebras; reposição de massa; Recuperação rápida contra os danos químicos; Compatível com qualquer tipo de cabelo. Rendimento médio: 600g – Em média 60 aplicações, 220g – Em média 22 aplicações",
                howtouse: "Com os cabelos molhados e higienizados, aplique o Blend de proteínas Olenka em mechas grandes do comprimento até as pontas, massageando e distribuindo com as mãos, ou com ajuda de um pente médio. Deixe agir por 10 minutos e em seguida enxágue com água em abundância. Finalize com a linha Finalizados Happy End Olenka. Pode ser usado em conjunto com qualquer tratamento Olenka Cosméticos."

            },
            {
                id: '5',
                name: "BLOND LEX",
                imagem: "https://olenkacosmeticos.com.br/wp-content/uploads/2020/02/imagem_1057_1018_0_bp_ultrap.png",
                benefits: "Antecipa-se aos danos das químicas de descoloração e coloração reduzindo ainda mais os danos sem atrapalhar o processo químico, possibilitando transformações saudáveis e cores muito mais intensas e duradouras.",
                services: "Sistema de proteção para descolorações e colorações, Rendimento médio: 25 aplicações.",
                howtouse: "Adicione 15ml do Ultra Defense Blond Plex Olenka à cumbuca de coloração ou descoloração e siga corretamente o passo a passo e as recomendações correspondentes ao produto utilizado.​​​​​​​"

            },
            {
                id: '6',
                name: "KIT PROFISSIONAL HIDRA 3'",
                imagem: "https://olenkacosmeticos.com.br/wp-content/uploads/2020/02/mont_banner_produtos_hidra3-01.png",
                benefits: "Pró-vitamina B5, óleos essenciais de buriti, monoi e proteína da seda. Resultados impressionantes já na primeira aplicação. Facilidade e diferencial também na aplicação. Passo 1 + passo 2 podem ser utilizados em casa como linha home care; Passo 1 + passo 2 + passo 3 apenas para uso profissional.",
                services: "Passos 1 e 2 = Hidratação profunda em 3 minutos; Passos 1, 2 e 3 = Hidratação profissional com recuperação; Rendimento médio: 45 aplicações.",
                howtouse: "Lave os cabelos com o Shampoo Hidratante Hidra 3′ Olenka, massageando o couro cabeludo suavemente com a ponta dos dedos. Enxágue sem dobrar ou torcer os fios e repita a operação retirando o excesso de água. Após lavar os cabelos com o Shampoo Hidratante Hidra 3′ Olenka, aplique, em mechas grandes, a Dual Mask Hidra 3′ Olenka, do comprimento até as pontas, com o auxílio de um pente médio, e massageie com as mãos. Deixe agir por 3 minutos, para obter máxima hidratação, e, em seguida, enxágue com água fria ou morna em abundância. Após a finalização do processo com a Dual Mask Hidra 3′ Olenka, aplique, em mechas grandes, a Web Mask Hidra 3′ Olenka, do comprimento até as pontas, com o auxílio de um pente médio, e massageie com as mãos. Deixe agir por 3 minutos, para obter máxima hidratação, e, em seguida, enxágue com água em abundância.​​​​​​​"

            },
            {
                id: '7',
                name: "KIT BANHO DE COLÁGENO",
                imagem: "https://olenkacosmeticos.com.br/wp-content/uploads/2020/02/mockup_banho_de_colageno_display-RTW-1024x880.png",
                benefits: "O Shake de Hidratação e Nutrição Intensiva F1 Nano Colágeno Olenka conta com o exclusivo ativo Complex 369, uma estrutura de ômegas 3, 6 e 9 rico em lipídeos e ácidos graxos essenciais, unidos e em pesos moleculares balanceados, proporcionando máxima performance em hidratação e nutrição intensiva aos cabelos. A Seiva de Reestruturação Profunda F2 Nano Colágeno Olenka proporciona aos fios o que há de mais avançado em performance e tecnologia de tratamento capilar. Suas nano moléculas de colágeno obtidas através de um processo enzimático único, proporcionam um tratamento extremamente profundo e imediato. A Proteína de Reestruturação e Finalização F3 Nano Colágeno Olenka consiste de um leave-in de reestruturação intensiva que atua entre as camadas superficiais do fio até a camada superior, proporcionando máxima interação, performance, proteção, acabamento e vida útil ao tratamento Nano Colágeno Olenka. Sendo assim, é absolutamente necessário finalizar qualquer força de tratamento, 1, 2, 3 ou 4, sempre com a Proteína de Reestruturação e Finalização F3 Nano Colágeno Olenka.",
                services: "FORÇA 1: 100% F1, Hidratação e nutrição intensiva; FORÇA 2: 75% F1 + 25% F2, Nutrição intensiva e fortalecimento; FORÇA 3: 50% F1 + 50% F2, Nano recuperação intensiva; FORÇA 4: 25% F1 + 75% F2, Recuperação intensiva e reposição de massa",
                howtouse: "Faça o diagnóstico apropriado do fio para detectar a necessidade de força 1, 2, 3 ou 4, então faça a mistura do Shake de Hidratação e Nutrição Intensiva F1 Nano Colágeno Olenka e da Seiva de Reestruturação Profunda F2 Nano Colágeno Olenka ou use-os separadamente, de acordo com o processo desejado. Com os cabelos lavados, úmidos e livres de gorduras e impurezas, aplique em mechas grandes a mistura de produtos de acordo com a força escolhida, distribuindo-a com a ajuda de um pente médio e deixe agir por 10 minutos. Enxágue e siga com a aplicação do Proteína de Reestruturação e Finalização F3 Nano Colágeno Olenka. Após a finalização do tratamento Nano Colágeno Olenka escolhido, aplique nos cabelos úmidos a Proteína de Reestruturação e Finalização F3 Nano Colágeno Olenka do comprimento até as pontas e utilize o calor para finalizar o processo e aumentar a vida útil do tratamento."

            },
            {
                id: '8',
                name: "KIT LAVATÓRIO ESPECIALIDADES",
                imagem: "https://olenkacosmeticos.com.br/wp-content/uploads/2020/02/mont_banner_produtos_especialidades-PROD.png",
                benefits: "O Shampoo de Tratamento Especialidades Olenka proporciona uma experiência única e agradável de purificação ao mesmo tempo que gera uma alta performance de limpeza sem ressecamento. Composto pelos ativos argan, macadâmia, nano queratina e pantenol, confere limpeza hidratante e ajuda no fortalecimento dos fios. O Condicionador de Tratamento Especialidades Olenka é enriquecido com argan, macadâmia, nano queratina e pantenol, que juntos proporcionam uma finalização perfeita em todos os procedimentos de hidratação e recuperação, além de conferir ao tratamento um maior desempenho de nutrição e ajudar no selamento dos fios, proporcionando assim maior durabilidade no tratamento, toque aveludado, brilho intenso, proteção máxima e acabamento profissional.",
                services: "Limpeza total das impurezas e máxima nutrição​​​​​​​",
                howtouse: "Com os cabelos molhados, distribua uniformemente o Shampoo de Tratamento Especialidades Olenka por todos os fios, massageando suavemente os cabelos e o couro cabeludo. Sem dobrar ou torcer os fios, enxágue abundantemente com água fria ou morna e repita o processo caso julgar necessário. Após a aplicação do Shampoo de Tratamento Especialidades Olenka, com os cabelos molhados, aplique em mechas grandes o Condicionador de Tratamento Especialidades Olenka do meio até as pontas, distribuindo por todos os fios com a ajuda de um pente médio e massageando com as mãos. Deixe agir por 5 minutos e, em seguida, enxágue com água fria ou morna em abundância, retirando totalmente o produto, então finalize com a linha de finalizadores Happy End Olenka.​​​​​​​"

            },
            {
                id: '9',
                name: "MÁSCARA PARA FINALIZAÇÃO QUÍMICA ROYAL FINISH",
                imagem: "https://olenkacosmeticos.com.br/wp-content/uploads/2020/02/mockup_royal-finish_500g-818x1024.png",
                benefits: "Máscara para finalização química universal com 21 aminoácidos e óleos essenciais de mirra e cálamo. Restabelece o pH fisiológico da fibra, finalizando e protegendo de futuras agressões. Desenvolvida para proteger, potencializar e finalizar qualquer tipo de ação química, seja por descolorações, colorações, alisamentos, entre outras, a Máscara Universal Pro Royal Finish Olenka atua estabilizando e devolvendo o pH natural e saudável dos fios, além de conter óleo de cálamo e mirra, que oferecem muito mais brilho e hidratação aos cabelos.",
                services: "Finaliza qualquer tipo de química (colorações, descolorações e alisamentos com ou sem formol), Rendimento médio: 40 aplicações.",
                howtouse: "Após a finalização do processo com a Máscara de Blindagem Royal Soft Olenka ou com a Máscara de Realinhamento Royal Look Olenka, entre outros procedimentos químicos, aplique a Máscara Universal Pro Royal Finish Olenka, com o auxílio de um pente, do comprimento até as pontas, massageando os fios com suavidade. Deixe agir por 10 minutos para perfeita finalização química e então enxágue com água em abundância.​​​​​​​"

            },
            {
                id: '10',
                name: "SELF CARE CABELOS CACHEADOS",
                imagem: "https://olenkacosmeticos.com.br/wp-content/uploads/2020/02/mockup_selfcare_mascara_250ml_cacheadosSS-1024x881.png",
                benefits: "O Self Care Cabelos Cacheados Olenka é enriquecido com Elastina, Óleo de Coco e Ômegas 6, 7 e 9, e somados aos seus ativos de última geração geram uma limpeza revigorante e fortalecedora para os cachos. Ele também prepara os cachos para se manterem firmes e saudáveis ao longo do dia, evitando o ressecamento e o frizz, e ainda prolongando a sensação de limpeza e frescor.",
                services: "Com sistema DUAL MASK; Sem pausa, condiciona. Com pausa, trata.",
                howtouse: "Lave os cabelos com o Shampoo Cabelos Cacheados Self Care Olenka, massageando o couro cabeludo suavemente com a ponta dos dedos. Enxágue e repita a operação. Em seguida, aplique a Dual Mousse Cabelos Cacheados Self Care Olenka nos cabelos úmidos do comprimento até as pontas, com o auxílio de um pente, massageando os fios com suavidade. Deixe agir por 10 minutos para obter máxima hidratação e então enxágue com água em abundância. Finalize com a linha de finalizadores Happy End Olenka. Para condicionamento dos fios não é necessário deixar tempo de pausa.​​​​​​​"

            },
            {
                id: '11',
                name: "Self Care Regeneração Intensa",
                imagem: "https://olenkacosmeticos.com.br/wp-content/uploads/2020/02/mockup_selfcare_shampoo_250ml_regeneracao-1-1024x880.png",
                benefits: "O Self Care Regeneração Intensa Olenka foi desenvolvido para proporcionar máximo tratamento sem pesar os cabelos. Sua tecnologia dispensa o uso de Condicionador desenvolvendo o papel de Condicionamento (sem pausa) e Tratamento (com pausa). Rica em Extrato de Quinoa e Óleos essenciais, Mirra e Cálamo, confere alta performance e durabilidade a qualquer tratamento profissional.",
                services: "Com sistema DUAL MASK; Sem pausa, condiciona. Com pausa, trata.",
                howtouse: "Lave os cabelos com o Shampoo Cabelos Cacheados Self Care Olenka, massageando o couro cabeludo suavemente com a ponta dos dedos. Enxágue e repita a operação. Em seguida, aplique a Dual Mousse Cabelos Cacheados Self Care Olenka nos cabelos úmidos do comprimento até as pontas, com o auxílio de um pente, massageando os fios com suavidade. Deixe agir por 10 minutos para obter máxima hidratação e então enxágue com água em abundância. Finalize com a linha de finalizadores Happy End Olenka. Para condicionamento dos fios não é necessário deixar tempo de pausa.​​​​​​​"

            },
            {
                id: '12',
                name: "SELF CARE PROTEÇÃO DE LOIROS",
                imagem: "https://olenkacosmeticos.com.br/wp-content/uploads/2020/02/mockup_selfcare_shampoo_250ml_loiros-01-1024x880.png",
                benefits: "O Self Care Proteção de Loiros foi desenvolvido para proporcionar máximo tratamento e durabilidade à cor dos cabelos. Sua tecnologia dispensa o uso de condicionador desenvolvendo o papel de Condicionamento (sem pausa) e Tratamento (com pausa). Rica em Extrato de Açaí, Vitaminas E e C, Óleo Essencial de Cálamo, confere alta performance, tratamento e durabilidade nos cabelos claros ou descoloridos.",
                services: "Com sistema DUAL MASK; Sem pausa, condiciona. Com pausa, trata.",
                howtouse: "Lave os cabelos com o Shampoo Cabelos Cacheados Self Care Olenka, massageando o couro cabeludo suavemente com a ponta dos dedos. Enxágue e repita a operação. Em seguida, aplique a Dual Mousse Cabelos Cacheados Self Care Olenka nos cabelos úmidos do comprimento até as pontas, com o auxílio de um pente, massageando os fios com suavidade. Deixe agir por 10 minutos para obter máxima hidratação e então enxágue com água em abundância. Finalize com a linha de finalizadores Happy End Olenka. Para condicionamento dos fios não é necessário deixar tempo de pausa.​​​​​​​"

            },
            {
                id: '13',
                name: "SELF CARE MANUTENÇÃO DO LISO",
                imagem: "https://olenkacosmeticos.com.br/wp-content/uploads/2020/02/mockup_selfcare_shampoo_250ml_liso-01-1024x880.png",
                benefits: "O Self Care Manutenção do Liso Olenka foi desenvolvido para proporcionar máximo tratamento e durabilidade aos cabelos lisos ou aos alisamentos profissionais de qualquer classe química. Sua tecnologia dispensa o uso de condicionador, desenvolvendo o papel de Condicionamento (sem pausa) e Tratamento (com pausa). Enriquecida com 21 Aminoácidos e 7 Óleos Essenciais, confere alta performance e tratamento aos fios. Por conter em sua base o exclusivo ativo Bio Lacase® Olenka, proporciona máximo desempenho e durabilidade aos cabelos lisos ou alisados.",
                services: "Com sistema DUAL MASK; Sem pausa, condiciona. Com pausa, trata.",
                howtouse: "Lave os cabelos com o Shampoo Cabelos Cacheados Self Care Olenka, massageando o couro cabeludo suavemente com a ponta dos dedos. Enxágue e repita a operação. Em seguida, aplique a Dual Mousse Cabelos Cacheados Self Care Olenka nos cabelos úmidos do comprimento até as pontas, com o auxílio de um pente, massageando os fios com suavidade. Deixe agir por 10 minutos para obter máxima hidratação e então enxágue com água em abundância. Finalize com a linha de finalizadores Happy End Olenka. Para condicionamento dos fios não é necessário deixar tempo de pausa.​​​​​​​"

            },
            {
                id: '14',
                name: "LINHA BLOND TECH",
                imagem: "https://olenkacosmeticos.com.br/wp-content/uploads/2020/06/mont_banner_produtos_blond_tech-BANNERR-min-300x300.png",
                benefits: "A Olenka Cosméticos desenvolveu uma linha completa de pó descolorante e emulsões reveladoras estabilizadas com fórmulas enriquecidas com óleo de argan e bio colágeno e fragrância suave.Emulsões em volumagens para atender todos os processo de coloração e descoloração.OX nas versões 10 vol., 20 vol., 30 vol., 35 vol. (matizada) e 40 vol.",
                services: "Enriquecidos com biocolágeno e queratina, Abre até 9 tons de forma segura, Baixo risco de danos, Sistema não volátil, Loiros platinados ou dourados, Suave fragrância, Colorações e descolorações.",
                howtouse: "Em um recipiente não metálico, misture na proporção de 1½ medida do CREME ATIVADOR HIDRATANTE OX BLOND TECH OU BLOND X para 1 medida do PÓ PARA DESCOLORAÇÃO BLONDTECH ou 1 medida de coloração capilar (por exemplo, 50g de pó para descoloração + 75ml de creme ativador hidratante) e proceda conforme seu modo de uso. DICA: em colorações, para uma maior cobertura de brancos utilize a proporção de 1:1."

            },
            {
                id: '15',
                name: "SPRAY DE ALTO BRILHO HAPPY END",
                imagem: "https://olenkacosmeticos.com.br/wp-content/uploads/2020/02/mockup_happy-end_spray_60ml-min-1024x880.png",
                benefits: "Por intermédio de vitaminas e proteína do linho, o Spray de Alto Brilho Happy End Olenka desenvolve, em volta do fio, um filme biológico rico em agentes antioxidantes que protegem o cabelo de ações químicas e físicas. Os silicones nobres promovem alto brilho e toque aveludado. Este produto pode ser utilizado em todos os tipos de cabelo.",
                services: "Finalização e proteção térmica, Doador de alto brilho.",
                howtouse: "Antes ou após a escovação, borrife o Spray de Alto Brilho Happy End Olenka sobre os cabelos úmidos ou secos à distância de 30 cm, de 1 a 3 vezes, por todos os fios, sem excessos. Este produto também pode ser utilizado a qualquer momento do dia, caso seja necessário."

            },
            {
                id: '16',
                name: "ÓLEO DE REPARAÇÃO HAPPY END",
                imagem: "https://olenkacosmeticos.com.br/wp-content/uploads/2020/02/mockup_happy-end_oleo_60ml-min-1024x881.png",
                benefits: "7 em 1: Argan, ojon, macadâmia, mirra, cálamo, monoi e buriti; Por meio de seleção cuidadosa de 7 óleos ricos em ômega 6 e 9, o Óleo de Reparação Happy End Olenka promove hidratação intensa, retenção hídrica e recuperação dos lipídios naturais dos cabelos danificados. Possui propriedades de hidratação, maciez, revitalização e melhora a flexibilidade dos fios, além de contribuir para a redução da perda de proteínas capilares, aumentando assim a resistência às ações químicas e físicas.",
                services: "Finalização e proteção térmica, Desodorização capilar, Potencializador de colorações e hidratações, Proteção da pele para colorações capilares",
                howtouse: "Antes ou após a escovação, aplique de 3 a 6 gotas do Óleo de Reparação Happy End Olenka nas palmas das mãos e espalhe no cabelo, do comprimento até as pontas. Também pode ser utilizado a qualquer momento do dia, se for necessário. Dica: nos processos de reconstrução, hidratação ou coloração, pode-se adicionar 3 doses, para potencializar os resultados."

            },
            {
                id: '17',
                name: "POMADA DE FINALIZAÇÃO SECA HAPPY END",
                imagem: "https://olenkacosmeticos.com.br/wp-content/uploads/2020/02/mockup_happy-end_pomada_80g-1024x764.png",
                benefits: "Cera de abelha + proteína da seda; A Pomada de Finalização Seca Happy End Olenka promove um filme protetor em volta dos fios que permite a finalização e a estilização de qualquer penteado. Seus ativos permitem potente retenção hídrica, que protege os cabelos e proporciona alta hidratação, e contam com alta tecnologia que auxilia no controle da proliferação de caspa e oferece leveza aos cabelos.",
                services: "Finalização e estilização de looks e penteados",
                howtouse: "Coloque pequena quantidade da Pomada de Finalização Seca Happy End Olenka na ponta dos dedos e espalhe na palma da mão, então aplique sobre os cabelos modelando-os como desejar. Este produto pode ser usado tanto por homens quanto por mulheres."

            },
            {
                id: '18',
                name: "ATIVADOR DE CACHOS SECRET CURL",
                imagem: "https://olenkacosmeticos.com.br/wp-content/uploads/2020/02/ATIVADOR-DE-CACHOS-SECRET-CURL-1024x881.png",
                benefits: "Elastina; Ômega 3; Óleo de Coco; O Ativador de Cachos Secret Curl Olenka contém em sua estrutura a união perfeita da elastina, ômega 3 e do óleo de coco, que juntos proporcionam cachos mais definidos e hidratados. Além de uma ótima definição, Secret Curl é poderoso no controle do frizz.",
                services: "Definição de cachos, Controle do frizz",
                howtouse: "Com os cabelos úmidos, pulverize de baixo para cima o Ativador de Cachos Secret Curl Happy End Olenka sem excessos e distribua por todos os fios com a ajuda de um pente largo. Se desejar, aperte os cachos com as mãos para aumentar a definição. Seque os cabelos com um secador difusor na temperatura máxima e na velocidade baixa."

            },
            {
                id: '19',
                name: "HAIR SPRAY",
                imagem: "https://olenkacosmeticos.com.br/wp-content/uploads/2020/02/mockup_hair-spray01_400ml-min-1536x1321.png",
                benefits: "O Hair Spray Happy End Olenka finaliza o penteado e protege do frizz sem deixar resíduos ou ressecar o cabelo. Ele prolonga muito a duração dos penteados, pois forma um filme sobre os fios que os protege da umidade sem deixa-los endurecidos, e por conter óleos nobres de tratamento em sua formulação, proporciona brilho extremo ao resultado final. É indicado para todos os tipos de cabelo, além de proporcionar uma excelente fixação suave, flexível, e invisível, gerar uma secagem rápida e manter o brilho e a vitalidade do cabelo. Seca rapidamente.",
                services: "Acabamento profissional, Penteados e tranças, Prolongador de escovas e alisamentos, Prolongador de Baby Liss, Estilização de cortes",
                howtouse: "Modo de usar: Agite bem o Hair Spray Happy End Olenka e aplique de maneira uniforme com leves jatos nos cabelos secos, mantendo uma distância de 25 cm a 35 cm. Em caso de entupimento da válvula, retire o atuador e enxague as partes (atuador e válvula) com água aquecida do chuveiro, que ele voltará ao seu funcionamento normal."

            },
            {
                id: '20',
                name: "CC CREAM LEAVE IN 13x1 HAPPY END",
                imagem: "https://olenkacosmeticos.com.br/wp-content/uploads/2020/06/mont_produtos_cc-cream.png",
                benefits: "Desenvolvido para ser utilizado em todas as ocasiões, o CC Cream Leave-in Universal Happy End Olenka e seus ativos especiais deixam os fios soltos, leves, brilhosos e fáceis de pentear. Enriquecido com proteína do linho + vitaminas C e E, este produto proporciona ainda maior durabilidade da cor e de ações químicas.  O CC Cream Leave-in Universal Happy End Olenka pode ser utilizado em todos os tipos de cabelos.",
                services: "Protetor térmico, Finalizador universal, Desodorizador capilar, Mais durabilidade da cor, Mais durabilidade do alisamento, Mais durabilidade da hidratação, Proteção dos fios contra agressões provocadas por piscina e mar, Proteção contra ação do tempo, Facilitador de escovação, Doador de alto brilho, Toque aveludado, Alto poder hidratante, Antieletricidade estática.",
                howtouse: "Antes ou após a escovação, aplique uma pequena quantidade do CC Cream Leave-in Universal Happy End Olenka nas mãos e espalhe, com o auxílio de um pente, nos cabelos úmidos. Escove os fios para obter melhor resultado de selamento das cutículas e brilho intenso. Também pode ser utilizado a qualquer momento do dia, caso seja necessário. Dica: podem ser adicionadas 3 (três) doses nos processos de reconstrução, hidratação ou coloração, para potencializar os resultados."

            },
            {
                id: '21',
                name: "PÓ MODELADOR",
                imagem: "https://olenkacosmeticos.com.br/wp-content/uploads/2020/11/mockup_happy-end_po-modelador_20g-min-1.png",
                benefits: "Cria volume e disciplina os fios com aspecto natural e toque seco, para aquele look perfeito. Desenvolvida para todos os tipos de cabelo, a Pomada em Pó Happy End serve para modelar sem deixar aquela textura dos produtos pastosos ou muito armado dos fixadores em spray. Volume e disciplina dos seus cabelos na medida certa sem ressecar e com uma leveza única. Na raíz proporciona volume natural, no comprimento texturiza e modela.",
                services: "Finalização estilizada; Finalização para cabelos finos; Aumento de volume; Penteados e tranças; Fixador de Waves.",
                howtouse: "Com os cabelos levemente úmidos ou secos de acordo com o gosto, aplique e distribua com as mãos estilizando e dando o formato desejado."

            },
            {
                id: '22',
                name: 'ROYAL LOOK',
                imagem: 'https://olenkacosmeticos.com.br/wp-content/uploads/2020/06/mockup_royal_look_pack-caixa-min-300-1-300x300.png',
                benefits: `O Shampoo Dilatador Royal Look promove limpeza profunda, sem danificar ou agredir os fios, eliminando totalmente os resíduos que interferem nos resultados de ações químicas, e isso desenvolve maleabilidade e corpo aos fios.

                O Alisante Capilar Royal Look promove, ao mesmo tempo, o realinhamento, tratamento e hidratação dos fios, pois possui a capacidade de fortalecer e proteger a fibra capilar de dentro para fora.`,
                services: `Enziomoplastia (realinhamento enzimático)
                Nano recuperação enzimática (S.O.S)
                Defrisagem enzimática (escova de longa duração)`,
                howtouse: `1. Lave os cabelos com o SHAMPOO DILATADOR ROYAL LOOK, massageando o couro cabeludo suavemente com a ponta dos dedos. Enxágue e repita a operação retirando o excesso de água.

                2. Após lavar os cabelos com o SHAMPOO DILATADOR ROYAL LOOK, seque de 50% a 70% dos fios, com o auxílio de um secador.
                
                3. Divida em 4 partes iguais. Aplique o ALISANTE CAPILAR ROYAL LOOK, mecha por mecha, com o auxílio de um pente, deixando-a agir por 30 a 60 minutos.
                
                4. Enxágue totalmente o cabelo com água em abundância.
                
                5. Seque e escove o cabelo.
                
                6. Pranche devagar, em mechas finas, em média 10 vezes, sempre, mais a raiz e menos as pontas.
                
                ATENÇÃO!
                
                – FAÇA ANTES O TESTE DE ALERGIA!
                
                – APLIQUE PEQUENA QUANTIDADE DO PRODUTO NO ANTEBRAÇO E NA NUCA.
                
                – PARA CABELOS LOIROS OU COLORIDOS, ADEQUE A TEMPERATURA DA PRANCHA ENTRE 180 E 200 °C.
                
                Rendimento: em média, 20 aplicações.
                
                `
            },
            {
                id: '23',
                name: 'BIO RECONSTRUTOR SELF CARE',
                imagem: 'https://olenkacosmeticos.com.br/wp-content/uploads/2020/06/mockup_bio-reconstrutor_selfcare_shampoo_01-280ml-1-300x300.png',
                benefits: `O Shampoo de Tratamento Bio Reconstrutor Self Care Olenka proporciona uma higienização profunda sem ressecar os fios. Seu blend de ativos revitalizadores composto de aminoácidos, queratina e 7 óleos essenciais, proporciona máxima interação com a Dual Mousse de Reconstrução Bio Reconstrutor Self Care Olenka, criando a perfeita sinergia e aumentando ao máximo a performance do tratamento.

                A Dual Mousse de Reconstrução Bio Reconstrutor Self Care Olenka promove uma reparação e reposição de massa imediata, instantânea e profunda nos fios, e sua ação com nano ativos reestrutura os fios de dentro para fora, atingindo todas as camadas capilares. Possui aminoácidos, queratina e 7 óleos essenciais, que juntos tratam e recuperam os fios de dentro para fora, tendo um mecanismo exclusivo de ação única, permitindo uma alta performance na recuperação capilar.`,
                services: `Recuperação capilar intensa
                S.O.S. para reações químicas adversas
                Cauterização e queratinização
                Potencialização de reconstruções e hidratações
                Rendimento médio: 20 aplicações`,
                howtouse: `Lave os cabelos com o Shampoo de Tratamento Bio Reconstrutor Self Care Olenka, massageando o couro cabeludo suavemente com a ponta dos dedos. Enxágue e repita a operação. Em seguida, aplique a Dual Mousse de Reconstrução Bio Reconstrutor Self Care Olenka nos cabelos ainda úmidos, do comprimento até as pontas, com o auxílio de um pente, massageando os fios com suavidade. Deixe agir por 10 minutos para obter máxima hidratação e então enxágue com água em abundância. Finalize com a linha de Finalizadores Happy End Olenka.

                Observação: Este produto não é uma manutenção diária, use apenas em caso de necessidade, com espaço de pelo menos uma semana entre as aplicações. Em caso de cortes químicos ou danos mais complexos, procure a ajuda e orientação de um profissional cabeleireiro.`
            },
            {
                id: '24',
                name: 'SHAMPOO PURIFICANTE DE TRATAMENTO E CONDICIONADOR REVITALIZANTE DE TRATAMENTO',
                imagem: 'https://olenkacosmeticos.com.br/wp-content/uploads/2020/04/mockup_mens-care_shampoo01_1500ml-min.png',
                benefits: `A linha de lavatório Men’s Care Olenka foi desenvolvida com exclusivo ativo OKnexil, que estimula e purifica o couro cabeludo, auxiliando no fortalecimento e regeneração dos cabelos. Seus ativos Aloe Vera, Extrato de Menta e Óleo de Melaleuca, poderosos fortificantes e hidratantes capilares não pesam e não agridem o couro cabeludo, conferindo alto poder hidratante, brilho intenso, sensação de refrescância e toque sedoso inigualável os cabelos.`,
                services: `Rendimento médio: 150 aplicações`,
                howtouse: `Com os cabelos molhados, aplique uma pequena quantidade do Shampoo Purificante de Tratamento Men’s Care Olenka e distribua com a ajuda das mãos massageando suavemente com as pontas dos dedos, então enxágue e repita o processo caso julgar necessário. Após utilizar o Shampoo Purificante de Tratamento Men’s Care Olenka, aplique uma pequena quantidade apenas nos fios do Condicionador Revitalizante de Tratamento Men’s Care Olenka e distribua do comprimento até as pontas, então deixe agir por 3 minutos e enxágue sem deixar resíduos, seguindo-se da aplicação do Óleo Hidratante 2X1 Men’s Care Olenka.`
            },
            {
                id: '25',
                name: "SHAMPOO E CONDICIONADOR MEN'S CARE HOME CARE",
                imagem: 'https://olenkacosmeticos.com.br/wp-content/uploads/2020/04/mockup_mens-care_shampoo01_300ml-min.png',
                benefits: `A linha Home Care Men’s Care Olenka foi desenvolvida com exclusivo ativo OKnexil, que estimula e purifica o couro cabeludo, auxiliando no fortalecimento e regeneração dos cabelos. Seus ativos Aloe Vera, Extrato de Menta e Óleo de Melaleuca, poderosos fortificantes e hidratantes capilares não pesam e não agridem o couro cabeludo, conferindo alto poder hidratante, brilho intenso, sensação de refrescância e toque sedoso inigualável os cabelos.`,
                services: `Rendimento médio: 40 aplicações`,
                howtouse: `Com os cabelos molhados, aplique uma pequena quantidade do Shampoo Purificante de Tratamento Men’s Care Olenka e distribua com a ajuda das mãos massageando suavemente com as pontas dos dedos, então enxágue e repita o processo caso julgar necessário. Após utilizar o Shampoo Purificante de Tratamento Men’s Care Olenka, aplique uma pequena quantidade apenas nos fios do Condicionador Revitalizante de Tratamento Men’s Care Olenka e distribua do comprimento até as pontas, então deixe agir por 3 minutos e enxágue sem deixar resíduos, seguindo-se da aplicação do Óleo Hidratante 2X1 Men’s Care Olenka.`
            },
            {
                id: '26',
                name: 'WASH 3X1 - SHAMPOO PARA CORPO, BARBA E CABELO',
                imagem: 'https://olenkacosmeticos.com.br/wp-content/uploads/2020/04/mockup_mens-care_wash3x1-01_300ml-min.png',
                benefits: `O Wash 3x1Shampoo para Corpo, Barba e Cabelo Olenka Men’s Care reúne em um único produto tudo o que um homem precisa para sua higienização, de forma rápida, precisa e eficaz. Sua formulação permite máxima limpeza em todo o corpo, associado aos ativos Aloe Vera e Óleo de Melaleuca que conferem alto poder hidratante, brilho intenso, sensação de refrescância e toque sedoso, tanto para a pele quanto para cabelo e barba. Seu poderoso  e exclusivo ativo OKnexil estimula e purifica o couro cabeludo e pele, auxiliando no fortalecimento e regeneração de cabelos e barba.`,
                services: `Rendimento médio: 35 aplicações (corpo, barba e cabelo)`,
                howtouse: `Aplique uma pequena quantidade do Wash 3X1 Men’s Care Olenka nos cabelos, barba e corpo, então distribua pela região e massageie suavemente com a ajuda das mãos e enxágue repetindo o processo caso julgar necessário. Após o processo finalize com o Óleo Hidratante para Cabelo e Barba 2X1 Men’s Care Olenka.

                Dica: O Wash 3X1 Men’s Care Olenka também pode ser associado a esponjas de banho para melhor distribuição e limpeza na região corporal.`
            },
            {
                id: '27',
                name: 'BALM PRÉ E PÓS BARBA',
                imagem: 'https://olenkacosmeticos.com.br/wp-content/uploads/2020/04/mockup_mens-care_balm2x1_300ml-RTW-1-min.png',
                benefits: `O Balm Pré e Pós Barba 2×1 Olenka Men’s Care é enriquecido com os poderosos ativos Aloe Vera e Tea Tree (Melaleuca) que proporcionam deslizar incrível aliado ao alto poder hidratante dos pelos o que auxilia em um barbear muito mais fácil e sem dor, até mesmo em barbas com pelos mais grossos e peles sensíveis. Sua composição em forma de bálsamo permite máxima visualização sem formar espuma. Tudo isso associado ao alto poder de regeneração da Alantoína, composição que atua diretamente na regeneração, hidratação e calmante da pele, sensações indispensáveis quando o assunto é pós barbear.`,
                services: `Rendimento médio: 200 aplicações`,
                howtouse: `Barbear: Com o rosto limpo e livre de impurezas, aplique uma pequena quantidade do Balm Pré e Pós Barba 2X1 Men’s Care Olenka na região a ser barbeada e deslize a lâmina como desejar e em qualquer sentido. Enxágue ao final.

                Pós barbear: Ao finalizar o barbear, aplique uma pequena quantidade do Balm Pré e Pós Barba 2X1 Men’s Care Olenka na pele para sentir uma sensação única de hidratação e regeneração.
                
                Dica: Caso tenha barba, utilize o Óleo Hidratante 2X1 Men’s Care Olenka todos os dias nos pelos para dar continuidade ao tratamento.`
            },
            {
                id: '28',
                name: 'POMADA DE FINALIZAÇÃO SECA',
                imagem: 'https://olenkacosmeticos.com.br/wp-content/uploads/2020/04/mockup_mens-care_pomada-brilho01_80ml-min.png',
                benefits: `A Pomada de Finalização Seca Olenka Men’s Care permite perfeita finalização e estilização de penteados. Seus ativos Aloe Vera e Melaleuca proporcionam hidratação e proteção aos fios até a próxima lavagem.`,
                services: `Rendimento médio: 50 aplicações`,
                howtouse: `Com os cabelos levemente úmidos ou secos, aplique a Pomada de Finalização Seca Men’s Care Olenka e distribua com as mãos estilizando e dando o formato desejado.`
            },
            {
                id: '29',
                name: 'POMADA DE FINALIZAÇÃO COM BRILHO',
                imagem: 'https://olenkacosmeticos.com.br/wp-content/uploads/2020/04/mockup_mens-care_pomada-brilho01_80ml-min.png',
                benefits: `A Pomada de Finalização com Brilho Olenka Men’s Care permite perfeita finalização e estilização de penteados. Seus ativos Aloe Vera e Melaleuca proporcionam hidratação e proteção aos fios até a próxima lavagem.`,
                services: `Rendimento médio: 50 aplicações`,
                howtouse: `Com os cabelos levemente úmidos ou secos, aplique a Pomada de Finalização com Brilho Men’s Care Olenka e distribua com as mãos estilizando e dando o formato desejado.`
            },
            {
                id: '30',
                name: 'TÔNICO ANTI-QUEDA PARA BARBA E CABELO 2X1',
                imagem: 'https://olenkacosmeticos.com.br/wp-content/uploads/2020/04/mockup_mens-care_tonico-01_100ml-min.png',
                benefits: `POTENTE EFEITO ANTI-QUEDA E NASCIMENTO DE NOVOS FIOS. EFICÁCIA COMPROVADA APÓS 4 SEMANAS.

                O Tônico Anti-Queda para Barba e Cabelo 2X1 Olenka Men’s Care com exclusivo sistema OKnexil atua estimulando, controlando e auxiliando diretamente de forma comprovada e eficaz a queda de cabelo, oleosidade, caspa, seborreia entre outras patologias que acometem o couro cabeludo. Sua performance ainda estimula de forma comprovada o nascimento de novos fios já na quarta semana de uso contínuo.`,
                services: `Rendimento médio: 60 aplicações (Cabelo e Barba)`,
                howtouse: `Após utilizar a linha Men’s Care Olenka para higienizar, tratar e hidratar os cabelos, retire o excesso de umidade com a ajuda de uma toalha e pulverize o Tônico Anti-Queda 2×1 Men’s Care Olenka por toda a região da barba e do couro cabeludo, então massageie suavemente com as pontas dos dedos  para a perfeita penetração do produto e não enxágue. Deixe agir até o dia seguinte. Use diariamente.`
            },
            {
                id: '31',
                name: 'ÓLEO HIDRATANTE 2X1',
                imagem: 'https://olenkacosmeticos.com.br/wp-content/uploads/2020/04/mockup_mens-care_oleo01_60ml-min.png',
                benefits: `O Óleo Hidratante Men’s Care Olenka é enriquecido com óleo de melaleuca e aloe vera e gera pelos e cabelos muito mais sedosos, brilhantes e com toque suave além de formar uma barreira protetora contra organismos externos, impurezas e danos do tempo. Associado a elastina, gera resistência e fortalecimento dos fios, gerando cabelos e barbas mais fortes e revitalizados.

                `,
                services: `Rendimento médio: 60 aplicações

                `,
                howtouse: `Aplique uma pequena quantidade nos cabelos ou na barba do Óleo Hidratante 2×1 Men’s Care Olenka distribuindo-o com a ajuda de um pente ou com as mãos. Pode ser aplicado todos os dias, depois do barbear ou da lavagem dos cabelos.

                `
            },
            {
                id: '32',
                name: 'MUP COLOR COLORAÇÃO',
                imagem: 'https://mupcolor.com.br/wp-content/uploads/2019/12/Coloracao_mup_color-600.jpg',
                benefits: `A MUP foi desenvolvida com a alta tecnologia que intensifica a ação dos agentes implantados ao fio. Uma linha completa e exclusiva para uso profissional, com 63 cores e  que oferece cuidado e respeito a cada fio com maior proteção do couro cabeludo.`,
                services: `Para criar resultados de cor, comece avaliando a cor e as condições do cabelo de sua cliente. Esse diagnóstico lhe ajudará a determinar como atingir os resultados que você e sua cliente querem. Comece com o fio de cabelo limpo e seco.`,
                howtouse: `PASSO 1:

                DETERMINE A COR NATURAL DA QUAL VOCÊ ESTÁ PARTINDO E O PERCENTUAL DE
                BRANCOS
                
                A cor em cabelos naturais é a definição referencial de quanto escuro ou claro é um cabelo.
                Tipicamente, as referências de cor variam do 1, a mais escura (preto), até a altura 10 (louro
                claríssimo), como podemos ver antes nas escalas de melanina. Porém, diferentes marcas de
                coloração podem apresentar definições ligeiramente diferentes.
                
                AJUSTANDO PARA CABELOS COM MAIS DE 50% DE BRANCO
                
                1 - Ajuste de tom:
                
                Cabelo sem pigmento tem um tom frio. A utilização de cores frias em cabelos brancos
                resultará numa cobertura incompleta. Para obter a cobertura completa de cabelos brancos,
                adicione um tom natural ou dourado à formula da coloração.
                
                PASSO 2:
                
                DETERMINE A ALTURA DE TOM QUE DESEJA ATINGIR
                
                Consulte sua cliente e determine se a cor será mais clara, mais escura, da mesma altura de
                tom ou somente uma mudança de nuance.

                PASSO 3:

DETERMINE A NUANCE DESEJADA

O tom da cor é a sua nuance dominante. Todos os tons podem ser descritos como quentes
ou frios.

PASSO 4:

ESCOLHA O OX ADEQUADO

A escolha correta do ox definirá o grau de clareamento a ser atingido,

                `
            },
        ]
    },

    {
        banner: "lizze",
        content: [
            {
                id: '1',
                name: 'LIZZE SUPREME',
                imagem: "https://lizze.com.br/images/produtos/prancha/suprem/supreme-icon.png",
                howtouse: '',
                services: '',
                benefits: `ALISA O DOBRO DE CABELO:
                A Única prancha com 40 milímetros que alisa mechas duas vezes maiores.
                Trabalhe metade do tempo e tenha o liso perfeito;
                NOVA POR MUITO MAIS TEMPO:
A Única com gabinete inovador que não descasca com o uso; 
SUPER TECNOLOGIA DE AQUECIMENTO:
Expande perfeitamente 485°F nas placas de titânio com acabamento dourado impecável;`
            },
            {
                id: '2',
                name: 'LIZZE EXTREME',
                imagem: "https://lizze.com.br/images/produtos/prancha/extremeico.png",
                howtouse: '',
                services: '',
                benefits: `REDUZ DE 2 HORAS PARA APENAS 40 MINUTOS EM UMA PROGRESSIVA!
                TECNOLOGIA NANO TITANIUM
Protege os fios do contato direto com a chapa de alumínio, facilitando ainda mais o deslizamento, tornando o processo de alisamento mais rápido, preciso e uniforme.

Economize 70% do seu tempo com a prancha de titânio mais quente do mercado. O melhor resultado, o melhor brilho e o melhor alisamento em um só produto!
As placas se ajustam perfeitamente às mechas, modelando e deslizando com mais facilidade sem danificar os fios.
20°C mais quente!
                `
            },
            {
                id: '3',
                name: 'SECADOR SUPREME',
                imagem: "https://lizze.com.br/images/produtos/secador/secador_supreme/p-5.png",
                howtouse: '',
                services: '',
                benefits: `O MAIS POTENTE
                O único que realmente entrega 2600 Watts de potência, desempenho que proporciona economia de tempo ao profissional.
                METADE DO TEMPO
Sua performance faz com que uma escovação seja realizada com a metade do tempo.

PENSADO PARA O PROFISSIONAL
O Secador Lizze Supreme foi pensado exclusivamente no profissional, sua leveza torna ele um secador completo, aumentando ganhos dos cabeleireiros e reduzindo a exaustão e dores por esforço contínuo.
UM PRODUTO ESPECIAL
PARA UMA PESSOA ESPECIAL!

                `
            },
            {
                id: '4',
                name: 'SECADOR EXTREME',
                imagem: "https://lizze.com.br/images/produtos/secador/extreme/extremeico.png",
                howtouse: '',
                services: '',
                benefits: `
                METADE DO TEMPO DE SECAGEM:
O único que realmente entrega 2400 Watts de potência, desempenho que proporciona economia de tempo ao profissional.
 Sua performance faz com que uma escovação seja realizada com a metade do tempo.
 MAIS LUCRO PARA O PROFISSIONAL



O Secador Lizze Extreme foi pensado exclusivamente no profissional, sua leveza torna ele um secador completo, aumentando ganhos dos cabeleireiros e reduzindo a exaustão e dores por esforço contínuo.

O único que realmente entrega o que promete. A sensação de ter um equipamento Lizze é indescritível.
                `
            },
            {
                id: '5',
                name: 'MÁQUINA EXTREME',
                imagem: "https://lizze.com.br/images/produtos/maquinaextreme/maquina-extrmete-icon.png",
                howtouse: '',
                services: '',
                benefits: `CORTES 20% MAIS RÁPIDOS:
                Projetada especialmente para você profissional.
                Ganhe tempo e aumente seu lucro.
                O VERDADEIRO PODER DO CORTE:
Potência extrema que garante cortes impecáveis sem travar.
8 PENTES
Crie degradês incríveis com acabamentos perfeitos.
PRODUTO PROFISSIONAL
PENSADA PARA BARBEIROS EXIGENTES.
                `
            },
            {
                id: '6',
                name: 'PHOTON EXTREME',
                imagem: "https://lizze.com.br/images/produtos/outros/photon/photonextreme.png",
                howtouse: '',
                services: '',
                benefits: `
                CABELOS MACIOS, HIDRATADOS E SEDOSOS!
                O Photon Lizze é provido de altíssima tecnologia, capaz de proporcionar resultados espetaculares nos fios de cabelo. Isto porque suas ondas de luz Azul de 450 nanômetros, entram em contato diretamente com a cutícula do cabelo, obtendo resultados mais duradouros e surpreendentes. Além disso, sua Luz Vermelha de 600 nanômetros 
                ativa a circulação e fortalece a fibra capilar.

                `
            },
            {
                id: '7',
                name: 'PHOTON SUPREME',
                imagem: "https://lizze.com.br/images/produtos/outros/photon/photonSupreme.png",
                howtouse: '',
                services: '',
                benefits: `
                O Único com
TRÊS FUNÇÕES!!! AZUL: Acelera processos químicos e atinge camadas mais profundas;

VERMELHO: Potencializa crescimento e fortalecimento;

VERDE: Renova fibra capilar e nutri os Fios.




                `
            },
            {
                id: '8',
                name: 'PHOTON LIZZE',
                imagem: "https://lizze.com.br/images/produtos/outros/photon.png",
                howtouse: '',
                services: '',
                benefits: `
                TECNOLOGIA INOVADORA:
                O Photon Lizze é provido de altíssima tecnologia, capaz de proporcionar resultados espetaculares nos fios de cabelo. Isto porque suas ondas de luz Azul de 450 nanômetros, entram em contato diretamente com a cutícula do cabelo,
                 obtendo resultados mais duradouros e surpreendentes.
                  Ao entrar em contato com o cabelo,
                   o laser inicia as reações químicas entre os compostos do produto distribuído
                    no cabelo, potencializando seu efeito e prolongando sua durabilidade através da penetração e fixação do produto nas fibras capilares.
                `
            },
            {
                id: '9',
                name: 'LIZZE CURLY',
                imagem: "https://lizze.com.br/images/produtos/curly/curly-icone.png",
                howtouse: '',
                services: '',
                benefits: `
                O MELHOR CACHEADOR AUTOMÁTICO!
                CACHOS PERFEITOS EM 8 SEGUNDOS
                Economize tempo! Mais praticidade e sem esforço.
                AVISO SONORO:
O Lizze Curly informa quando o cacho é finalizado.
RESULTADO DE SALÃO EM CASA!
TAMBÉM PROJETADO PARA USO DOMÉSTICO.

                `
            },
            {
                id: '10',
                name: 'MODELADOR EXTREME',
                imagem: "https://lizze.com.br/images/produtos/outros/modelador.png",
                howtouse: '',
                services: '',
                benefits: `
                AJUSTE DE TEMPERATURA:
O modelador Lizze Extreme possui 5 modulações de temperatura, podendo reduzir para cabelos mais finos e frágeis. O único que atinge 480°F, que modela perfeitamente em apenas 5 segundos, devido ao alto aquecimento.

Economize 80% do seu tempo com o modelador mais quente do mercado. O melhor resultado, o melhor brilho e o cacho mais definido em um só produto.
AQUECIMENTO ULTRA RÁPIDO: Restaura automaticamente a temperatura do cilindro devido ao seu sistema de aquecimento em MCH.

                `
            },
            {
                id: '11',
                name: 'LIZZE EXTREME SLIM',
                imagem: "https://lizze.com.br/images/produtos/prancha/extremeSlim.png",
                howtouse: '',
                services: '',
                benefits: `
                SEGURANÇA E SAÚDE PARA OS CABELOS!
                ATINGE 250°C (480°F)!
Com características surpreendentes, a Prancha Lizze EXTREME SLIM possui ultima geração de tecnologia. Atingindo a temperatura de 250°C, e proporcionando resultados impressionantes.

A Prancha Lizze Extreme Slim possui ajuste de temperatura de 300.
As Pranchas Lizze possuem uma resistência que se autocontrola (MCH). Essa tecnologia garante que a temperatura não oscile, resultando em maior segurança e saúde aos fios.

                `
            },
            {
                id: '12',
                name: 'LIZZE PREMIUM',
                imagem: "https://lizze.com.br/images/produtos/prancha/pranchaPremium.png",
                howtouse: '',
                services: '',
                benefits: `
                GARANTINDO FIOS MAIS BRILHANTES E SEDOSOS!
                REPOSIÇÃO RÁPIDA DE CALOR!
Com características surpreendentes, a Prancha Lizze PREMIUM L possui ultima geração de tecnologia. Atingindo a temperatura de 240°C, e proporcionando resultados impressionantes.

A Prancha Lizze possui sistema de aquecimento que proporciona alta condutividade térmica, mantendo a temperatura da chapa constante através da reposição instantânea de calor.
Protege os fios do contato direto com a chapa de alumínio, facilitando ainda mais o deslizamento, tornando o processo de alisamento mais rápido, preciso e uniforme.







10°C mais quente!
                `
            },
            {
                id: '13',
                name: 'LIZZE PLATINUM',
                imagem: "https://lizze.com.br/images/produtos/prancha/platinum.png",
                howtouse: '',
                services: '',
                benefits: `
                ATINGE 230°C (450°F)
A Prancha Lizze possui sistema de aquecimento que proporciona alta condutividade térmica, mantendo a temperatura da chapa constante através da reposição instantânea de calor.
As Pranchas Lizze possuem uma resistência que se autocontrola (PTC). Essa tecnologia garante que a temperatura não oscile, resultando em maior segurança e saúde aos fios.
                `
            },
            {
                id: '14',
                name: 'POWER LIZZE',
                imagem: "https://lizze.com.br/images/produtos/secador/secadorPower.png",
                howtouse: '',
                services: '',
                benefits: `
                PENTEADOS IMPECÁVEIS!
                REFERÊNCIA NO MERCADO
Este secador é referência no mercado de beleza, e é indicado para o uso exclusivo do profissional de beleza. Sua potência permite escovação ágil e modelagem perfeita. O Power Lizze também é o primeiro secador do mercado a possuir 2200 W nas duas voltagens.
3 TEMPERATURAS E 2 VELOCIDADES



Com 3 temperaturas e 2 velocidades, conta ainda com botão de jato de ar frio, o que permite melhor fixação e penteados mais disciplinados. O bico de ar feito em PPS suporta altas temperaturas, direciona o fluxo de ar e facilita a modelagem.

                `
            },
            {
                id: '15',
                name: 'LIZZE SALONPRO',
                imagem: "https://lizze.com.br/images/produtos/secador/salonpro/salonpro-icon.png",
                howtouse: '',
                services: '',
                benefits: `
                SECADOR PROFISSIONAL: 
Secadores potentes e leves!
Feitos sob medida para atender suas necessidades.
3 TEMPERATURAS E 2 VELOCIDADES!



Com 3 temperaturas e 2 velocidades, conta ainda com botão de jato de ar frio,
 o que permite melhor fixação e penteados mais disciplinados. O bico de ar feito em PPS suporta altas temperaturas, direciona o fluxo de ar e facilita a modelagem.
                `
            },
            {
                id: '16',
                name: 'LIZZE SMART',
                imagem: "https://lizze.com.br/images/produtos/secador/smart/smart-icon.png",
                howtouse: '',
                services: '',
                benefits: `
                3 TEMPERATURAS E 2 VELOCIDADES!



Com 3 temperaturas e 2 velocidades, conta ainda com botão de jato de ar frio, o que permite melhor fixação e penteados mais disciplinados. O bico de ar feito em PPS suporta altas temperaturas, direciona o fluxo de ar e facilita a modelagem.
                `
            },
            {
                id: '17',
                name: 'LIZZE COMPACT',
                imagem: "https://lizze.com.br/images/produtos/secador/compact.png",
                howtouse: '',
                services: '',
                benefits: `
                SECAGEM RÁPIDA E EFICAZ
Secador super leve com uma ventilação e temperatura adequada para tratar e selar os fios do cabelo deixando com brilho e maciez. Devido sua ventilação eficaz promove uma secagem com muita rapidez e agilidade.



O Secador Lizze Compact é um secador profissional que traz maior praticidade e inovação para o seu dia-a-dia. Extremamente leve e com design moderno, possui 2 velocidades e três temperaturas. Indicado para todos os tipos de cabelo.
PRATICO E SEGURO



O Lizze Compact possui também uma alça para pendurar o produto e uma grade traseira removível para facilitar a higienização do secador, garantindo mais segurança e saúde.
                `
            },
            {
                id: '18',
                name: 'MINI LIZZE',
                imagem: "https://lizze.com.br/images/produtos/prancha/mini.png",
                howtouse: '',
                services: '',
                benefits: `
                LEVE, PORTÁTIL E COM DESIGN É INOVADOR!
                LED INDICADOR
A Prancha Mini Lizze possui um LED indicador de ligada, que permite visualizar quando a prancha está em funcionamento. Garantindo mais segurança ao profissional.
SEGURANÇA E SAÚDE PARA OS CABELOS



Como o próprio nome já diz, a Prancha Mini Lizze possui apenas 15cm, é a menor prancha profissional do mercado. Leve, portátil e com design é inovador, é adaptável à qualquer tipo de bolsa ou compartimento, sendo perfeita para todos os momentos e ideal para alisar a raiz e retocar penteados.

                `
            },
        ]
    },

    {
        banner: "muy_biela",
        content: [
            {
                id: '1',
                name: "GEL MUY BIELA STRONG CLEAR 24G",
                imagem: "https://static.wixstatic.com/media/5ae693_a8596a736ed343d0b266b098f1243f8f~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_a8596a736ed343d0b266b098f1243f8f~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `
                    
        Gel de construção, manutenção e trabalhos artísticos encapsulados, com coloração clear (transparente).
        
        A escolha certa para a curvatura C perfeita!
        
         
        
        Sabe aquele gel que você sempre desejou, só que não sabia? Este é o Strong!
        
         
        
        A coleção de géis UV Strong Muy Biela vai te proporcionar unhas mais curvadas e duradouras, como você e sua cliente desejam.
        
         
        
        Faça curvatura C sem o uso de presilhas ou pinças, utilizando somente a mão.
        
         
        
        SUA CURVATURA C FICARÁ RÍGIDA, DO JEITINHO QUE VOCÊ FEZ, APÓS SECAR NA CABINE!
        
         
        
        Pode ser utilizado em qualquer técnica de alongamento, já que é um gel de construção. 
        
         
        
        Assim como todos os géis da linha MUY BIELA, é autonivelante, muito resistente, não amarela e não gera desconforto quando vai a cabine. 
        
         
        
        Também pode ser utilizado nas manutenções, preservando a cor do alongamento de acordo com a cor do seu Gel Strong preferido.
        
         
        
        Atualmente, a coleção contem os seguintes itens, que podem ser adquiridos individualmente:
        
        CLEAR - NATURAL CLASSIC -  NUDE - MILK
        
         
        
        Perfeito para profissionais.
                `,
            },
            {
                id: '2',
                name: "GEL SHINY MILK MUY BIELA 24G",
                imagem: "https://static.wixstatic.com/media/5ae693_18141288121d49c8829e198be704d00d~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_18141288121d49c8829e198be704d00d~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `
                Gel de construção e manutenção, com coloração branca suave com brilho.
        
        Cor perfeita para você que quer fazer a borda livre da sua francesa reversa (branco suave levemente brihante) ou aquele baby boomer lindo.
        
        Este é o Gel Muy Biela Shiny Milk!
        
         
        
        A coleção de géis UV Shiny Muy Biela vai te proporcionar unhas duradouras e ainda mais lindas, como você e sua cliente desejam.
        
         
        
        Pode ser utilizado em qualquer técnica de alongamento, já que é um gel de construção. 
        
         
        
        Assim como todos os géis da linha MUY BIELA, é autonivelante, muito resistente, não amarela e não gera desconforto quando vai a cabine. 
        
         
        
        Também pode ser utilizado nas manutenções, preservando a cor do alongamento de acordo com a cor do seu Gel Shiny preferido.
        
         
        
        Perfeito para profissionais.
                `,
            },
            {
                id: '3',
                name: "GEL SHINY NUDE MUY BIELA 24G",
                imagem: "https://static.wixstatic.com/media/5ae693_70d67d7007b0494cad6adcf924cef716~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_70d67d7007b0494cad6adcf924cef716~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `
                    
        Gel de construção e manutenção, com coloração nude com brilho.
        
        Cor perfeita para você que quer camuflar o leito da sua francesa reversa (nude levemente brihante) ou aquele baby boomer lindo.
        
        Este é o Gel Muy Biela Shiny Nude!
        
         
        
        A coleção de géis UV Shiny Muy Biela vai te proporcionar unhas duradouras e ainda mais lindas, como você e sua cliente desejam.
        
         
        
        Pode ser utilizado em qualquer técnica de alongamento, já que é um gel de construção. 
        
         
        
        Assim como todos os géis da linha MUY BIELA, é autonivelante, muito resistente, não amarela e não gera desconforto quando vai a cabine. 
        
         
        
        Também pode ser utilizado nas manutenções, preservando a cor do alongamento de acordo com a cor do seu Gel Shiny preferido.
        
         
        
        Perfeito para profissionais.
        
         R$ 86,32 Preço normalR$ 79,90Preço promocional
        Quantidade
        1
        
        Adicionar ao Carrinho
                `,
            },
            {
                id: '4',
                name: "GEL FAST FIBER MUY BIELA 24G",
                imagem: "https://static.wixstatic.com/media/5ae693_de06620c3adb4b4ebcb42d71246f7bd2~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_de06620c3adb4b4ebcb42d71246f7bd2~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `
                Gel com fibra em um unico produto!
        
         
        
                A combinação perfeita entre o melhor gel e a melhor fibra!
                
                 
                
                Pode ser utilizado na construção e tambem na manutenção, com coloração pink (rosa).
                
                 
                
                Fragrancia especial de Chiclete!!!!!
                
                 
                
                A coleção de géis UV Classicos Muy Biela vai te proporcionar unhas mais duradouras, como você e sua cliente desejam.
                
                 
                
                Pode ser utilizado nas unhas de Gel com Tips, Moldadas, etc.
                
                 
                
                Pode ser utilizado em qualquer técnica de alongamento, já que é um gel de construção. 
                
                 
                
                Assim como todos os géis da linha MUY BIELA, é autonivelante, muito resistente, não amarela e não gera desconforto quando vai a cabine.
                
                 
                Perfeito para profissionais.
                Contem 24g
                `,
            },
            {
                id: '5',
                name: "GEL BASE MUY BIELA 20G",
                imagem: "https://static.wixstatic.com/media/5ae693_06777dcfa6744e2889f20d29ba694703~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_06777dcfa6744e2889f20d29ba694703~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Gel Base Muy Biela, com coloração clear.
        
         
        
                O Gel Base Muy Biela é muito versátil e é recomendado para construção de unhas artificiais como CAPA BASE.
                
                 
                
                SUPER ADERENCIADOR ENTRE UNHAS E GEL.
                
                Proporciona maior aderencia entre a unha natural e camada de gel, evitando descolamentos e infiltrações.
                
                Também pode ser utilizado em blindagens, como capa base.
                
                 
                
                Faça unhas artificiais duradouras em suas clientes.
                
                 
                
                Pode ser utilizado em qualquer técnica de alongamento de gel.
                
                 
                
                Também pode ser utilizado nas manutenções, preservando seu alongamento.
                
                 
                
                Perfeito para profissionais.
                
                 
                
                Contem 20g`,
            },
            {
                id: '6',
                name: "GEL MUY BIELA COVER NUDE 24G",
                imagem: "https://static.wixstatic.com/media/5ae693_9efd73dc02cd4e5c9d829a863c3b5d65~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_9efd73dc02cd4e5c9d829a863c3b5d65~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `
                Gel de construção e manutenção, com coloração cover nude.
        
         
        
                Sabe aquela cor que você sempre quiz para fazer a sua francesa reversa mas não encontrava?
                
                Este é o Gel Muy Biela Cover Nude!
                
                 
                
                A coleção de géis UV Classic Muy Biela vai te proporcionar unhas mais duradouras, como você e sua cliente desejam.
                
                 
                
                Pode ser utilizado em qualquer técnica de alongamento, já que é um gel de construção. 
                
                 
                
                Assim como todos os géis da linha MUY BIELA, é autonivelante, muito resistente, não amarela e não gera desconforto quando vai a cabine. 
                
                 
                
                Também pode ser utilizado nas manutenções, preservando a cor do alongamento de acordo com a cor do seu Gel Classic preferido.
                
                 
                
                Perfeito para profissionais.
                `,
            },
            {
                id: '7',
                name: "UNHAS DE FIBRA MUY BIELA SLIM C/ 50 UNID 7CM",
                imagem: "https://static.wixstatic.com/media/5ae693_5ae6ec49c75b4f7fa6aa50fe93521eab~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_5ae6ec49c75b4f7fa6aa50fe93521eab~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Desenvolvida como extrutura de reforço nas unhas artificiais que utilizam gel como contrutor, as fibras para unhas Muy Biela fazem papel fundamental, evitando que se quebrem com facilidade.
        
         
        
                Tem como diferenciais, acabamento especial que miniminiza a possibilidade de fixar na pele, evitando assim que você sinta aquele incomodo que parece penetrar na pele.
                
                 
                
                Desenvolvida para otimizar o tempo das profissionais, a Fibra Slim Muy Biela 7cm vem na medida certa e com uma unica tira, é possivel contruir até 3 unhas, dependendo do tamanho do alongamento.
                
                 
                
                Para manutençao, uma unica tira de Fibra Muy Biela Slim 7cm proporcionará de 4 a 6 unhas, dependendo do tamanho do alongamento.
                
                 
                
                Material diferenciado que absorve totalmente o gel. Não marca os fios dentro alongamento.
                
                 
                
                Contem 50 unidades de 7cm.`,
            },
            {
                id: '8',
                name: "UNHAS DE FIBRA MUY BIELA SLIM C/ 100 UNID 7CM",
                imagem: "https://static.wixstatic.com/media/5ae693_5ae6ec49c75b4f7fa6aa50fe93521eab~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_5ae6ec49c75b4f7fa6aa50fe93521eab~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Desenvolvida como extrutura de reforço nas unhas artificiais que utilizam gel como contrutor, as fibras para unhas Muy Biela fazem papel fundamental, evitando que se quebrem com facilidade.
        
         
        
                Tem como diferenciais, acabamento especial que miniminiza a possibilidade de fixar na pele, evitando assim que você sinta aquele incomodo que parece penetrar na pele.
                
                 
                
                Desenvolvida para otimizar o tempo das profissionais, a Fibra Slim Muy Biela 7cm vem na medida certa e com uma unica tira, é possivel contruir até 3 unhas, dependendo do tamanho do alongamento.
                
                 
                
                Para manutençao, uma unica tira de Fibra Muy Biela Slim 7cm proporcionará de 4 a 6 unhas, dependendo do tamanho do alongamento.
                
                 
                
                Material diferenciado que absorve totalmente o gel. Não marca os fios dentro alongamento.
                
                 
                
                Contem 100 unidades de 7cm.`,
            },
            {
                id: '9',
                name: "CLEAN HIGIENIZANTE MUY BIELA (PREP) ROSA 500 ML - REFIL",
                imagem: "https://static.wixstatic.com/media/5ae693_96663154cee54642af95bf1e059d900d~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_96663154cee54642af95bf1e059d900d~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `A maneira mais eficaz de higienizar suas unhas.
        
                Atua também como higienizante para as mãos, pois tem em sua formulação componentes altamente eficazes, proporcionando maior segurança para a cliente e também para a profissional.
                
                Utilizado também para moldar as unhas de Polymer Gel (nosso gel em pasta) durante o processo de construção e manutenção das unhas.
                
                Disponivel em embalagens de 60ml, 120ml e 500ml.`,
            },
            {
                id: '10',
                name: "CLEAN HIGIENIZANTE MUY BIELA (PREP) 500ml",
                imagem: "https://static.wixstatic.com/media/5ae693_5a84aaff5d6e4c0a87d0f3504f0debb6~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_5a84aaff5d6e4c0a87d0f3504f0debb6~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `A maneira mais eficaz de higienizar suas unhas.
        
                Atua também como higienizante para as mãos, pois tem em sua formulação componentes altamente eficazes, proporcionando maior segurança para a cliente e também para a profissional.
                
                Utilizado também para moldar as unhas de Polymer Gel (nosso gel em pasta) durante o processo de construção e manutenção das unhas.
                
                Disponivel em embalagens de 60ml, 120ml e 500ml.`,
            },
            {
                id: '11',
                name: "GEL MUY BIELA STRONG MILK 24g",
                imagem: "https://static.wixstatic.com/media/5ae693_8a03422fb0b740858a1cace7812eb2e0~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_8a03422fb0b740858a1cace7812eb2e0~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Gel de construção e manutenção, com coloração branco leitoso suave.
        
         
        
                Sabe aquele gel que você sempre desejou, só que não sabia? Este é o Strong!
                
                 
                
                A coleção de géis UV Strong Muy Biela vai te proporcionar unhas mais curvadas e duradouras, como você e sua cliente desejam.
                
                 
                
                Faça curvatura C sem o uso de presilhas ou pinças, utilizando somente a mão.
                
                 
                
                SUA CURVATURA C FICARÁ RÍGIDA, DO JEITINHO QUE VOCÊ FEZ, APÓS SECAR NA CABINE!
                
                 
                
                Pode ser utilizado em qualquer técnica de alongamento, já que é um gel de construção. 
                
                 
                
                Assim como todos os géis da linha MUY BIELA, é autonivelante, muito resistente, não amarela e não gera desconforto quando vai a cabine. 
                
                 
                
                Também pode ser utilizado nas manutenções, preservando a cor do alongamento de acordo com a cor do seu Gel Strong preferido.
                
                 
                
                Atualmente, a coleção contem os seguintes itens, que podem ser adquiridos individualmente:
                
                CLEAR - NATURAL CLASSIC -  NUDE - MILK
                
                Perfeito para profissionais.`,
            },
            {
                id: '12',
                name: "GEL MUY BIELA STRONG NUDE 24G",
                imagem: "https://static.wixstatic.com/media/5ae693_14bf78520a434933b366b89298b01fd8~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_14bf78520a434933b366b89298b01fd8~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Gel de construção e manutenção, com coloração nude suave.
        
         
        
                A escolha certa para a curvatura C perfeita!
                
                 
                
                Sabe aquele gel que você sempre desejou, só que não sabia? Este é o Strong!
                
                 
                
                A coleção de géis UV Strong Muy Biela vai te proporcionar unhas mais curvadas e duradouras, como você e sua cliente desejam.
                
                 
                
                Faça curvatura C sem o uso de presilhas ou pinças, utilizando somente a mão.
                
                 
                
                SUA CURVATURA C FICARÁ RÍGIDA, DO JEITINHO QUE VOCÊ FEZ, APÓS SECAR NA CABINE!
                
                 
                
                Pode ser utilizado em qualquer técnica de alongamento, já que é um gel de construção. 
                
                 
                
                Assim como todos os géis da linha MUY BIELA, é autonivelante, muito resistente, não amarela e não gera desconforto quando vai a cabine. 
                
                 
                
                Também pode ser utilizado nas manutenções, preservando a cor do alongamento de acordo com a cor do seu Gel Strong preferido.
                
                 
                
                Atualmente, a coleção contem os seguintes itens, que podem ser adquiridos individualmente:
                
                CLEAR - NATURAL CLASSIC -  NUDE - MILK
                
                Perfeito para profissionais.`,
            },
            {
                id: '13',
                name: "GEL MUY BIELA STRONG NATURAL CLASSIC 15G",
                imagem: "https://static.wixstatic.com/media/5ae693_5e83d1f5c3344d51a659a04122f1b252~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_5e83d1f5c3344d51a659a04122f1b252~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Gel de construção e manutenção com coloração leitosa em tom pessego suave. TAMBÉM RECOMENDADO COMO GEL BASE.
        
         
        
                A escolha certa para a curvatura C perfeita!
                
                 
                
                Sabe aquele gel que você sempre desejou, só que não sabia? Este é o Strong!
                
                 
                
                A coleção de géis UV Strong Muy Biela vai te proporcionar unhas mais curvadas e duradouras, como você e sua cliente desejam.
                
                 
                
                Faça curvatura C sem o uso de presilhas ou pinças, utilizando somente a mão.
                
                 
                
                SUA CURVATURA C FICARÁ RÍGIDA, DO JEITINHO QUE VOCÊ FEZ, APÓS SECAR NA CABINE!
                
                 
                
                Pode ser utilizado em qualquer técnica de alongamento, já que é um gel de construção. 
                
                 
                
                Assim como todos os géis da linha MUY BIELA, é auto-nivelante, muito resistente, não amarela e não gera desconforto quando vai a cabine. 
                
                 
                
                Também pode ser utilizado nas manutenções, preservando a cor do alongamento de acordo com a cor do seu Gel Strong preferido.
                
                 
                
                Atualmente, a coleção contem os seguintes itens, que podem ser adquiridos individualmente:
                
                CLEAR - NATURAL CLASSIC -  NUDE - MILK
                
                Perfeito para profissionais.`,
            },
            {
                id: '14',
                name: "POLYMER GEL MUY BIELA CLASSIC WHITE 24G",
                imagem: "https://static.wixstatic.com/media/5ae693_3b13c1506f0a4756add4d6ed7d0f0898~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_3b13c1506f0a4756add4d6ed7d0f0898~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Gel de construção em pasta, fácil de moldar, muito resistente e sem cheiro.
        
         
        
                A surpreendente fórmula do Polymer Gel Muy Biela é o resultado da junção entre o gel e o acrílico de ótima qualidade.
                
                 
                
                Permite reforçar a unha natural em forma de blindagem das unhas;
                
                 
                
                Permite fazer reconstrução de unhas roidas;
                
                 
                
                Pode ser utilizado nas técnicas de alongamento no molde de papel na construção de unhas tradicionais, formatos especiais, sobre o clássico molde F1, sobre Tips e também sobre a pré-curvatura de unhas de fibra.
                
                 
                
                O grande diferencial é o controle absoluto sobre o produto, que permite trabalhar de forma tranquila e com absoluta facilidade, pois seca somente ao levar a cabine.
                
                 
                
                Por ser um gel em pasta, não escorre e construir unhas em formatos extremos é bem tranquilo.
                
                 
                
                Utiliza o Clean Muy Biela para molhar o pincel e modelar o produto (não necessita monomer).
                 
                
                Possui foto-iniciador UV e Led (cabine de 4 lâmpadas demora muito a secar, não indicamos).
                
                 
                
                Atualmente, a coleção contem os seguintes itens, que podem ser adquiridos individualmente:
                
                CLEAR - COVER NUDE - PINK - WHITE
                
                 
                
                É muito utilizado por profissionais que fazem trabalhos com unhas tradicionais, unhas médias e também unhas extremas.
                
                 
                
                Perfeito para profissionais.`,
            },
            {
                id: '15',
                name: "POLYMER GEL MUY BIELA CLASSIC PINK 24G",
                imagem: "https://static.wixstatic.com/media/5ae693_f761c4e3337840e9918f5b92b4e9b023~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_f761c4e3337840e9918f5b92b4e9b023~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Gel de construção em pasta, fácil de moldar, muito resistente e sem cheiro.
        
         
        
                A surpreendente fórmula do Polymer Gel Muy Biela é o resultado da junção entre o gel e o acrílico de ótima qualidade.
                
                 
                
                Permite reforçar a unha natural em forma de blindagem das unhas;
                
                 
                
                Permite fazer reconstrução de unhas roidas;
                
                 
                
                Pode ser utilizado nas técnicas de alongamento no molde de papel na construção de unhas tradicionais, formatos especiais, sobre o clássico molde F1, sobre Tips e também sobre a pré-curvatura de unhas de fibra.
                
                 
                
                O grande diferencial é o controle absoluto sobre o produto, que permite trabalhar de forma tranquila e com absoluta facilidade, pois seca somente ao levar a cabine.
                
                 
                
                Por ser um gel em pasta, não escorre e construir unhas em formatos extremos é bem tranquilo.
                
                 
                
                Utiliza o Clean Muy Biela para molhar o pincel e modelar o produto (não necessita monomer).
                 
                
                Possui foto-iniciador UV e Led (cabine de 4 lâmpadas demora muito a secar, não indicamos).
                
                 
                
                Atualmente, a coleção contem os seguintes itens, que podem ser adquiridos individualmente:
                
                CLEAR - COVER NUDE - PINK - WHITE
                
                 
                
                É muito utilizado por profissionais que fazem trabalhos com unhas tradicionais, unhas médias e também unhas extremas.
                
                 
                
                Perfeito para profissionais.`,
            },
            {
                id: '16',
                name: "POLYMER GEL MUY BIELA CLASSIC COVER NUDE 24G",
                imagem: "https://static.wixstatic.com/media/5ae693_c47d56b059fd4572b56c6d4f525b8e7d~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_c47d56b059fd4572b56c6d4f525b8e7d~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Gel de construção em pasta, fácil de moldar, muito resistente e sem cheiro.
        
         
        
                A surpreendente fórmula do Polymer Gel Muy Biela é o resultado da junção entre o gel e o acrílico de ótima qualidade.
                
                 
                
                Permite reforçar a unha natural em forma de blindagem das unhas;
                
                 
                
                Permite fazer reconstrução de unhas roidas;
                
                 
                
                Pode ser utilizado nas técnicas de alongamento no molde de papel na construção de unhas tradicionais, formatos especiais, sobre o clássico molde F1, sobre Tips e também sobre a pré-curvatura de unhas de fibra.
                
                 
                
                O grande diferencial é o controle absoluto sobre o produto, que permite trabalhar de forma tranquila e com absoluta facilidade, pois seca somente ao levar a cabine.
                
                 
                
                Por ser um gel em pasta, não escorre e construir unhas em formatos extremos é bem tranquilo.
                
                 
                
                Utiliza o Clean Muy Biela para molhar o pincel e modelar o produto (não necessita monomer).
                 
                
                Possui foto-iniciador UV e Led (cabine de 4 lâmpadas demora muito a secar, não indicamos).
                
                 
                
                Atualmente, a coleção contem os seguintes itens, que podem ser adquiridos individualmente:
                
                CLEAR - COVER NUDE - PINK - WHITE
                
                 
                
                É muito utilizado por profissionais que fazem trabalhos com unhas tradicionais, unhas médias e também unhas extremas.
                
                 
                
                Perfeito para profissionais.`,
            },
            {
                id: '17',
                name: "POLYMER GEL MUY BIELA COLORS 08 24G (Branco Leitoso Suave)",
                imagem: "https://static.wixstatic.com/media/5ae693_05e56559123e4683bd03e18a39bee352~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_05e56559123e4683bd03e18a39bee352~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Gel de construção em pasta, fácil de moldar, muito resistente e sem cheiro.
        
         
        
                A surpreendente fórmula do Polymer Gel Muy Biela é o resultado da junção entre o gel e o acrílico de ótima qualidade.
                
                 
                
                Permite reforçar a unha natural em forma de blindagem das unhas;
                
                 
                
                Permite fazer reconstrução de unhas roidas;
                
                 
                
                Pode ser utilizado nas técnicas de alongamento no molde de papel na construção de unhas tradicionais, formatos especiais, sobre o clássico molde F1, sobre Tips e também sobre a pré-curvatura de unhas de fibra.
                
                 
                
                O grande diferencial é o controle absoluto sobre o produto, que permite trabalhar de forma tranquila e com absoluta facilidade, pois seca somente ao levar a cabine.
                
                 
                
                Por ser um gel em pasta, não escorre e construir unhas em formatos extremos é bem tranquilo.
                
                 
                
                Utiliza o Clean Muy Biela para molhar o pincel e modelar o produto (não necessita monomer).
                 
                
                Possui foto-iniciador UV e Led (cabine de 4 lâmpadas demora muito a secar, não indicamos).
                
                 
                
                Atualmente, a coleção contem os seguintes itens, que podem ser adquiridos individualmente:
                
                05 (Cover Pink Suave) - 06 (Cover Coral) - 07 (Rosa Penelope) - 08 (Branco Leitoso Suave)
                
                 
                
                É muito utilizado por profissionais que fazem trabalhos com unhas tradicionais, unhas médias e também unhas extremas.
                
                 
                
                Perfeito para profissionais.`,
            },
            {
                id: '18',
                name: "POLYMER GEL MUY BIELA COLORS 07 24G (Rosa Penelope)",
                imagem: "https://static.wixstatic.com/media/5ae693_35f33844330f43fc842de877a7b8c6ff~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_35f33844330f43fc842de877a7b8c6ff~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Gel de construção em pasta, fácil de moldar, muito resistente e sem cheiro.
        
         
        
                A surpreendente fórmula do Polymer Gel Muy Biela é o resultado da junção entre o gel e o acrílico de ótima qualidade.
                
                 
                
                Permite reforçar a unha natural em forma de blindagem das unhas;
                
                 
                
                Permite fazer reconstrução de unhas roidas;
                
                 
                
                Pode ser utilizado nas técnicas de alongamento no molde de papel na construção de unhas tradicionais, formatos especiais, sobre o clássico molde F1, sobre Tips e também sobre a pré-curvatura de unhas de fibra.
                
                 
                
                O grande diferencial é o controle absoluto sobre o produto, que permite trabalhar de forma tranquila e com absoluta facilidade, pois seca somente ao levar a cabine.
                
                 
                
                Por ser um gel em pasta, não escorre e construir unhas em formatos extremos é bem tranquilo.
                
                 
                
                Utiliza o Clean Muy Biela para molhar o pincel e modelar o produto (não necessita monomer).
                 
                
                Possui foto-iniciador UV e Led (cabine de 4 lâmpadas demora muito a secar, não indicamos).
                
                 
                
                Atualmente, a coleção contem os seguintes itens, que podem ser adquiridos individualmente:
                
                05 (Cover Pink Suave) - 06 (Cover Coral) - 07 (Rosa Penelope) - 08 (Branco Leitoso Suave)
                
                 
                
                É muito utilizado por profissionais que fazem trabalhos com unhas tradicionais, unhas médias e também unhas extremas.
                
                 
                
                Perfeito para profissionais.`,
            },
            {
                id: '19',
                name: "POLYMER GEL MUY BIELA COLORS 06 24G (Cover Coral)",
                imagem: "https://static.wixstatic.com/media/5ae693_29b5bc65db924a8295d14e0df95211d0~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_29b5bc65db924a8295d14e0df95211d0~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Gel de construção em pasta, fácil de moldar, muito resistente e sem cheiro.
        
         
        
                A surpreendente fórmula do Polymer Gel Muy Biela é o resultado da junção entre o gel e o acrílico de ótima qualidade.
                
                 
                
                Permite reforçar a unha natural em forma de blindagem das unhas;
                
                 
                
                Permite fazer reconstrução de unhas roidas;
                
                 
                
                Pode ser utilizado nas técnicas de alongamento no molde de papel na construção de unhas tradicionais, formatos especiais, sobre o clássico molde F1, sobre Tips e também sobre a pré-curvatura de unhas de fibra.
                
                 
                
                O grande diferencial é o controle absoluto sobre o produto, que permite trabalhar de forma tranquila e com absoluta facilidade, pois seca somente ao levar a cabine.
                
                 
                
                Por ser um gel em pasta, não escorre e construir unhas em formatos extremos é bem tranquilo.
                
                 
                
                Utiliza o Clean Muy Biela para molhar o pincel e modelar o produto (não necessita monomer).
                 
                
                Possui foto-iniciador UV e Led (cabine de 4 lâmpadas demora muito a secar, não indicamos).
                
                 
                
                Atualmente, a coleção contem os seguintes itens, que podem ser adquiridos individualmente:
                
                05 (Cover Pink Suave) - 06 (Cover Coral) - 07 (Rosa Penelope) - 08 (Branco Leitoso Suave)
                
                 
                
                É muito utilizado por profissionais que fazem trabalhos com unhas tradicionais, unhas médias e também unhas extremas.
                
                 
                
                Perfeito para profissionais.`,
            },
            {
                id: '20',
                name: "POLYMER GEL MUY BIELA COLORS 05 24g (Cover Pink Suave)",
                imagem: "https://static.wixstatic.com/media/5ae693_006740a7f2154f6686e979b87474be44~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_006740a7f2154f6686e979b87474be44~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Gel de construção em pasta, fácil de moldar, muito resistente e sem cheiro.
        
         
        
                A surpreendente fórmula do Polymer Gel Muy Biela é o resultado da junção entre o gel e o acrílico de ótima qualidade.
                
                 
                
                Permite reforçar a unha natural em forma de blindagem das unhas;
                
                 
                
                Permite fazer reconstrução de unhas roidas;
                
                 
                
                Pode ser utilizado nas técnicas de alongamento no molde de papel na construção de unhas tradicionais, formatos especiais, sobre o clássico molde F1, sobre Tips e também sobre a pré-curvatura de unhas de fibra.
                
                 
                
                O grande diferencial é o controle absoluto sobre o produto, que permite trabalhar de forma tranquila e com absoluta facilidade, pois seca somente ao levar a cabine.
                
                 
                
                Por ser um gel em pasta, não escorre e construir unhas em formatos extremos é bem tranquilo.
                
                 
                
                Utiliza o Clean Muy Biela para molhar o pincel e modelar o produto (não necessita monomer).
                 
                
                Possui foto-iniciador UV e Led (cabine de 4 lâmpadas demora muito a secar, não indicamos).
                
                 
                
                Atualmente, a coleção contem os seguintes itens, que podem ser adquiridos individualmente:
                
                05 (Cover Pink Suave) - 06 (Cover Coral) - 07 (Rosa Penelope) - 08 (Branco Leitoso Suave)
                
                 
                
                É muito utilizado por profissionais que fazem trabalhos com unhas tradicionais, unhas médias e também unhas extremas.
                
                 
                
                Perfeito para profissionais.`
            },
            {
                id: '21',
                name: "POLYMER GEL MUY BIELA CLASSIC CLEAR 24G",
                imagem: "https://static.wixstatic.com/media/5ae693_df605cf40359460890e55e8d2f86502c~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_df605cf40359460890e55e8d2f86502c~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Gel de construção em pasta, fácil de moldar, muito resistente e sem cheiro.
        
         
        
                A surpreendente fórmula do Polymer Gel Muy Biela é o resultado da junção entre o gel e o acrílico de ótima qualidade.
                
                 
                
                Permite reforçar a unha natural em forma de blindagem das unhas;
                
                 
                
                Permite fazer reconstrução de unhas roidas;
                
                 
                
                Pode ser utilizado nas técnicas de alongamento no molde de papel na construção de unhas tradicionais, formatos especiais, sobre o clássico molde F1, sobre Tips e também sobre a pré-curvatura de unhas de fibra.
                
                 
                
                O grande diferencial é o controle absoluto sobre o produto, que permite trabalhar de forma tranquila e com absoluta facilidade, pois seca somente ao levar a cabine.
                
                 
                
                Por ser um gel em pasta, não escorre e construir unhas em formatos extremos é bem tranquilo.
                
                 
                
                Utiliza o Clean Muy Biela para molhar o pincel e modelar o produto (não necessita monomer).
                 
                
                Possui foto-iniciador UV e Led (cabine de 4 lâmpadas demora muito a secar, não indicamos).
                
                 
                
                Atualmente, a coleção contem os seguintes itens, que podem ser adquiridos individualmente:
                
                CLEAR - COVER NUDE - PINK - WHITE
                
                 
                
                É muito utilizado por profissionais que fazem trabalhos com unhas tradicionais, unhas médias e também unhas extremas.
                
                 
                
                Perfeito para profissionais.`,
            },
            {
                id: '22',
                name: "UNHAS DE FIBRA MUY BIELA DOUBLE C/ 100 UNID 7CM",
                imagem: "https://static.wixstatic.com/media/5ae693_462dfe457f294eb78e5ef57c7688ee52~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_462dfe457f294eb78e5ef57c7688ee52~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Desenvolvida como extrutura de reforço nas unhas artificiais que utilizam gel como contrutor, as fibras para unhas Muy Biela fazem papel fundamental, evitando que se quebrem com facilidade.
        
         
        
                Tem como diferenciais, acabamento especial que miniminiza a possibilidade de fixar na pele, evitando assim que você sinta aquele incomodo que parece fixar na pele.
                
                 
                
                Devido a alta quantidade de fios na composição da Fibra Double Muy Biela 7cm, com uma unica tira, é possivel contruir de 4 a 6 unhas, dependendo do tamanho do alongamento.
                
                 
                
                Para manutençao, uma unica tira de Fibra Double Muy Biela 7cm proporcionará de 8 a 12 unhas, dependendo do tamanho do alongamento.
                
                 
                
                Material diferenciado que absorve totalmente o gel. Não marca os fios dentro alongamento.
                
                 
                
                Contem 100 unidades de 7cm.`,
            },
            {
                id: '23',
                name: "UNHAS DE FIBRA MUY BIELA C/ 50 UNID 7CM",
                imagem: "https://static.wixstatic.com/media/5ae693_462dfe457f294eb78e5ef57c7688ee52~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_462dfe457f294eb78e5ef57c7688ee52~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Desenvolvida como extrutura de reforço nas unhas artificiais que utilizam gel como contrutor, as fibras para unhas Muy Biela fazem papel fundamental, evitando que se quebrem com facilidade.
        
         
        
                Tem como diferenciais, acabamento especial que miniminiza a possibilidade de fixar na pele, evitando assim que você sinta aquele incomodo que parece penetrar na pele.
                
                 
                
                Devido a alta quantidade de fios na composição da Fibra Double Muy Biela 7cm, com uma unica tira, é possivel contruir de 4 a 6 unhas, dependendo do tamanho do alongamento.
                
                 
                
                Para manutençao, uma unica tira de Fibra Double Muy Biela 7cm proporcionará de 8 a 12 unhas, dependendo do tamanho do alongamento.
                
                 
                
                Material diferenciado que absorve totalmente o gel. Não marca os fios dentro alongamento.
                
                 
                
                Contem 50 unidades de 7cm.`,
            },
            {
                id: '24',
                name: "TIPS MUY BIELA PARA UNHAS DE GEL SQUARE C/100",
                imagem: "https://static.wixstatic.com/media/5ae693_612f1980d2ff4e9b841c696ac95aebf5~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_612f1980d2ff4e9b841c696ac95aebf5~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Construa seu alongamento de unhas em gel com Tips de unhas. 
        
                Contem 10 tamanhos diferentes, para utilizar em todos os tipos de unhas.
                
                Perfeitas para manicures que fazem alongamentos de unhas.`,
            },
            {
                id: '25',
                name: "TIPS MUY BIELA PARA UNHAS DE GEL SORRISO C/100",
                imagem: "https://static.wixstatic.com/media/5ae693_612f1980d2ff4e9b841c696ac95aebf5~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_612f1980d2ff4e9b841c696ac95aebf5~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Construa seu alongamento de unhas em gel com Tips de unhas. 
        
                Contem 10 tamanhos diferentes, para utilizar em todos os tipos de unhas.
                
                Perfeitas para manicures que fazem alongamentos de unhas.`,
            },
            {
                id: '26',
                name: "PLACA MOLDE MUY BIELA PARA UNHAS ARTIF C/3",
                imagem: "https://static.wixstatic.com/media/5ae693_fcf4b99fbee44adfaf414f31fc385cda~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_fcf4b99fbee44adfaf414f31fc385cda~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: ``,
            },
            {
                id: '27',
                name: "MORCETTO MUY BIELA PARA UNHAS PEQUENO C/1",
                imagem: "https://static.wixstatic.com/media/5ae693_2898931ce69249a09d00caaaa7880375~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_2898931ce69249a09d00caaaa7880375~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `O Morcetto Muy Biela é recomendado para profissionais que necessitam fazer a curvatura C nas unhas de forma precisa.
        
                Sua curvatura no ponto certo faz com que as unhas fiquem curvadas na medida certa.
                
                Tamanho pequeno, para dedos com superficies de unhas tradicionais.`,
            },
            {
                id: '28',
                name: "LIXA MUY BIELA CINZA JUMBO 100/180 7''",
                imagem: "https://static.wixstatic.com/media/5ae693_54779953def54dd780be78bf3b8c38fa~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_54779953def54dd780be78bf3b8c38fa~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Lixas cuidadosamente desenvolvidas para profissionais de unhas artificiais, com gramatura 100/180.
        
         
        
                As lixas Muy Biela proporcionam as profissionais um alto rendimento, pois possuem gramaturas reais à sua identificação e são muito duráveis.
                
                 
                
                Faça o teste você mesma e sinta a diferença na durabilidade das lixas Muy Biela.
                
                 
                
                Fabricadas na Coréia com qualidade Europeia.
                
                 
                
                Faz parte da Coleção Lixas Muy Biela`,
            },
            {
                id: '29',
                name: "LIXA MUY BIELA BRANCA FINA 150/220 7''",
                imagem: "https://static.wixstatic.com/media/5ae693_309b471b08bb44e494781fadb4124379~mv2.png/v1/fill/w_980,h_551,al_c,q_90,usm_0.66_1.00_0.01/5ae693_309b471b08bb44e494781fadb4124379~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Lixa especial para fazer contornos das unhas, desbastar as quinas, os cantos do arco inferior, proximo ao ponto zero.
        
         
        
                Lixas cuidadosamente desenvolvidas para profissionais de unhas artificiais, com gramatura 150/220.
                
                 
                
                As lixas Muy Biela proporcionam as profissionais um alto rendimento, pois possuem gramaturas reais à sua identificação e são muito duráveis.
                
                 
                
                Faça o teste você mesma e sinta a diferença na durabilidade das lixas Muy Biela.
                
                 
                
                Fabricadas na Coréia com qualidade Europeia.
                
                 
                
                Faz parte da Coleção Lixas Muy Biela`,
            },
            {
                id: '30',
                name: "MOLDE MUY BIELA DE PAPEL PARA UNHAS NOVO C/50",
                imagem: "https://static.wixstatic.com/media/5ae693_dc66f134dd6c4a5684d746a54629cdb8~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_dc66f134dd6c4a5684d746a54629cdb8~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Desenvolvido por nossa fundadora e educadora master, Cristina Biela e pensado em todas as necessidades de uma iniciante à mais experiente profissional.
        
                Material diferenciado, com cola que não se solta quando vai a cabine e ao mesmo tempo, aceita ser descolado para pequenos ajustes.
                
                Tem em suas linhas informações que ajudam a identificar a construção do sorriso da francesa reversa e cortes de profundidade no momento de customização para unhas arredondadas, ovais e também linhas para identificar onde fazer o corte diagonal nos pontos zeros.`,
            },
            {
                id: '31',
                name: "MOLDE MUY BIELA DE PAPEL PARA UNHAS C/300",
                imagem: "https://static.wixstatic.com/media/5ae693_889f52b408b242b289e416d11cdf126e~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_889f52b408b242b289e416d11cdf126e~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Desenvolvido por nossa fundadora e educadora master, Cristina Biela e pensado em todas as necessidades de uma iniciante à mais experiente profissional.
        
                Material diferenciado, com cola que não se solta quando vai a cabine e ao mesmo tempo, aceita ser descolado para pequenos ajustes.
                
                Tem em suas linhas informações que ajudam a identificar a construção do sorriso da francesa reversa e cortes de profundidade no momento de customização para unhas arredondadas, ovais e também linhas para identificar onde fazer o corte diagonal nos pontos zeros.`,
            },
            {
                id: '32',
                name: "PINK GEL MUY BIELA UV2 30G",
                imagem: "https://static.wixstatic.com/media/5ae693_9f80d784d5074eab8e40b0018f35fd5a~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_9f80d784d5074eab8e40b0018f35fd5a~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Pode ser utilizado em qualquer técnica de alongamento, já que é um gel de construção.
        
         
        
                Em duas versões, o TRADICIONAL (potes brancos) ou FLEX (potes pretos), são indicados para todas as técnicas de unhas artificiais que utilizam gel UV, tais como UNHAS DE GEL, UNHAS DE FIBRA, UNHAS DE GEL MOLDADO, etc.
                
                 
                
                Assim como todos os géis da linha MUY BIELA, é auto-nivelante, muito resistente, não amarela e não gera desconforto quando vai a cabine. 
                
                 
                
                Também pode ser utilizado nas manutenções, preservando a cor do alongamento.
                
                 
                
                É o mais recomendado por profissionais de todo o Brasil!
                
                 
                
                Perfeito para profissionais.`,
            },
            {
                id: '33',
                name: "PINK GEL MUY BIELA UV2 24G",
                imagem: "https://static.wixstatic.com/media/5ae693_9f80d784d5074eab8e40b0018f35fd5a~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_9f80d784d5074eab8e40b0018f35fd5a~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Pode ser utilizado em qualquer técnica de alongamento, já que é um gel de construção.
        
         
        
                Em duas versões, o TRADICIONAL (potes brancos) ou FLEX (potes pretos), são indicados para todas as técnicas de unhas artificiais que utilizam gel UV, tais como UNHAS DE GEL, UNHAS DE FIBRA, UNHAS DE GEL MOLDADO, etc.
                
                 
                
                Assim como todos os géis da linha MUY BIELA, é auto-nivelante, muito resistente, não amarela e não gera desconforto quando vai a cabine. 
                
                 
                
                Também pode ser utilizado nas manutenções, preservando a cor do alongamento.
                
                 
                
                É o mais recomendado por profissionais de todo o Brasil!
                
                 
                
                Perfeito para profissionais.`,
            },
            {
                id: '34',
                name: "PINK GEL MUY BIELA UV2 15G",
                imagem: "https://static.wixstatic.com/media/5ae693_4cb99a76caf948bd9299a4865462a955~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_4cb99a76caf948bd9299a4865462a955~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Pode ser utilizado em qualquer técnica de alongamento, já que é um gel de construção.
        
         
        
                Em duas versões, o TRADICIONAL (potes brancos) ou FLEX (potes pretos), são indicados para todas as técnicas de unhas artificiais que utilizam gel UV, tais como UNHAS DE GEL, UNHAS DE FIBRA, UNHAS DE GEL MOLDADO, etc.
                
                 
                
                Assim como todos os géis da linha MUY BIELA, é auto-nivelante, muito resistente, não amarela e não gera desconforto quando vai a cabine. 
                
                 
                
                Também pode ser utilizado nas manutenções, preservando a cor do alongamento.
                
                 
                
                É o mais recomendado por profissionais de todo o Brasil!
                
                 
                
                Perfeito para profissionais.`,
            },
            {
                id: '35',
                name: "PINK GEL MUY BIELA UV1 24G",
                imagem: "https://static.wixstatic.com/media/5ae693_d7063b7013b6400aa510d9d6e611d298~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_d7063b7013b6400aa510d9d6e611d298~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Pode ser utilizado em qualquer técnica de alongamento, já que é um gel de construção.
        
         
        
                São indicados para todas as técnicas de unhas artificiais que utilizam gel UV, tais como UNHAS DE GEL, UNHAS DE FIBRA, UNHAS DE GEL MOLDADO, etc.
                
                 
                
                Assim como todos os géis da linha MUY BIELA, é autonivelante, muito resistente, não amarela e não gera desconforto quando vai a cabine. 
                
                 
                
                Mais recomendado para ser utilizado nas manutenções, preservando a cor do alongamento.
                
                 
                
                Perfeito para profissionais.`,
            },
            {
                id: '36',
                name: "GEL MUY BIELA VITRAL VERDE 15G (02)",
                imagem: "https://static.wixstatic.com/media/5ae693_02a335896a584838a22ae550a34a609f~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_02a335896a584838a22ae550a34a609f~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Por ser um gel colorido e sem cor de fundo (transparente) o GEL MUY BIELA VITRAL VERDE é um gel versátil, com múltiplas funções.
        
         
        
                Pode ser utilizado em qualquer técnica de alongamento, já que é um gel de construção. 
                
                 
                
                Assim como todos os géis da linha MUY BIELA, é autonivelante, resistente, não amarela e não gera desconforto quando vai a cabine. 
                
                 
                
                É altamente utilizado pelas profissionais que fazem trabalhos artísticos durante o processo de construção da unha artificial, como por exemplo, encapsulamentos de glitter, flores, folhas e tudo o que a criatividade imaginar.
                
                 
                
                Nas construções de unhas conhecidas como Francesa Reversa, este produto é utilizado para imitar a borda livre, dando maior beleza ao resultado final do seu trabalho.
                
                 
                
                Perfeito para profissionais.`,
            },
            {
                id: '37',
                name: "GEL MUY BIELA VITRAL ROSA 15G",
                imagem: "https://static.wixstatic.com/media/5ae693_bfce12d9f12b410e93a144ade91240c4~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_bfce12d9f12b410e93a144ade91240c4~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Por ser um gel colorido e sem cor de fundo (transparente) o GEL MUY BIELA VITRAL ROSA é um gel versátil, com múltiplas funções.
        
         
        
                Pode ser utilizado em qualquer técnica de alongamento, já que é um gel de construção. 
                
                 
                
                Assim como todos os géis da linha MUY BIELA, é autonivelante, resistente, não amarela e não gera desconforto quando vai a cabine. 
                
                 
                
                É altamente utilizado pelas profissionais que fazem trabalhos artísticos durante o processo de construção da unha artificial, como por exemplo, encapsulamentos de glitter, flores, folhas e tudo o que a criatividade imaginar.
                
                 
                
                Nas construções de unhas conhecidas como Francesa Reversa, este produto é utilizado para imitar a borda livre, dando maior beleza ao resultado final do seu trabalho.
                
                 
                
                Perfeito para profissionais.`,
            },
            {
                id: '38',
                name: "GEL MUY BIELA VITRAL LARANJA 15G",
                imagem: "https://static.wixstatic.com/media/5ae693_9ad0d1e951b9427d863f5b1bda021d27~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_9ad0d1e951b9427d863f5b1bda021d27~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Por ser um gel colorido e sem cor de fundo (transparente) o GEL MUY BIELA VITRAL LARANJA é um gel versátil, com múltiplas funções.
        
         
        
                Pode ser utilizado em qualquer técnica de alongamento, já que é um gel de construção. 
                
                 
                
                Assim como todos os géis da linha MUY BIELA, é autonivelante, resistente, não amarela e não gera desconforto quando vai a cabine. 
                
                 
                
                É altamente utilizado pelas profissionais que fazem trabalhos artísticos durante o processo de construção da unha artificial, como por exemplo, encapsulamentos de glitter, flores, folhas e tudo o que a criatividade imaginar.
                
                 
                
                Nas construções de unhas conhecidas como Francesa Reversa, este produto é utilizado para imitar a borda livre, dando maior beleza ao resultado final do seu trabalho.
                
                 
                
                Perfeito para profissionais.`,
            },
            {
                id: '39',
                name: "GEL MUY BIELA VITRAL AZUL 15G",
                imagem: "https://static.wixstatic.com/media/5ae693_749ae7ac09dc421aa5f18fd9a0c52488~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_749ae7ac09dc421aa5f18fd9a0c52488~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Por ser um gel colorido e sem cor de fundo (transparente) o GEL MUY BIELA VITRAL AZUL é um gel versátil, com múltiplas funções.
        
         
        
                Pode ser utilizado em qualquer técnica de alongamento, já que é um gel de construção. 
                
                 
                
                Assim como todos os géis da linha MUY BIELA, é autonivelante, resistente, não amarela e não gera desconforto quando vai a cabine. 
                
                 
                
                É altamente utilizado pelas profissionais que fazem trabalhos artísticos durante o processo de construção da unha artificial, como por exemplo, encapsulamentos de glitter, flores, folhas e tudo o que a criatividade imaginar.
                
                 
                
                Nas construções de unhas conhecidas como Francesa Reversa, este produto é utilizado para imitar a borda livre, dando maior beleza ao resultado final do seu trabalho.
                
                 
                
                Perfeito para profissionais.`,
            },
            {
                id: '40',
                name: "GEL MUY BIELA STRONG NATURAL CLASSIC 30G",
                imagem: "https://static.wixstatic.com/media/5ae693_b8c6b121c22f40338817183f4146e3c5~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_b8c6b121c22f40338817183f4146e3c5~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Gel de construção e manutenção com coloração leitosa em tom pessego suave. TAMBÉM RECOMENDADO COMO GEL BASE.
        
         
        
                A escolha certa para a curvatura C perfeita!
                
                 
                
                Sabe aquele gel que você sempre desejou, só que não sabia? Este é o Strong!
                
                 
                
                A coleção de géis UV Strong Muy Biela vai te proporcionar unhas mais curvadas e duradouras, como você e sua cliente desejam.
                
                 
                
                Faça curvatura C sem o uso de presilhas ou pinças, utilizando somente a mão.
                
                 
                
                SUA CURVATURA C FICARÁ RÍGIDA, DO JEITINHO QUE VOCÊ FEZ, APÓS SECAR NA CABINE!
                
                 
                
                Pode ser utilizado em qualquer técnica de alongamento, já que é um gel de construção. 
                
                 
                
                Assim como todos os géis da linha MUY BIELA, é auto-nivelante, muito resistente, não amarela e não gera desconforto quando vai a cabine. 
                
                 
                
                Também pode ser utilizado nas manutenções, preservando a cor do alongamento de acordo com a cor do seu Gel Strong preferido.
                
                 
                
                Atualmente, a coleção contem os seguintes itens, que podem ser adquiridos individualmente:
                
                CLEAR - NATURAL CLASSIC -  NUDE - MILK
                
                Perfeito para profissionais.`,
            },
            {
                id: '41',
                name: "GEL MUY BIELA STRONG NATURAL CLASSIC 24G",
                imagem: "https://static.wixstatic.com/media/5ae693_b8c6b121c22f40338817183f4146e3c5~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_b8c6b121c22f40338817183f4146e3c5~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Gel de construção e manutenção com coloração leitosa em tom pessego suave. TAMBÉM RECOMENDADO COMO GEL BASE.
        
         
        
                A escolha certa para a curvatura C perfeita!
                
                 
                
                Sabe aquele gel que você sempre desejou, só que não sabia? Este é o Strong!
                
                 
                
                A coleção de géis UV Strong Muy Biela vai te proporcionar unhas mais curvadas e duradouras, como você e sua cliente desejam.
                
                 
                
                Faça curvatura C sem o uso de presilhas ou pinças, utilizando somente a mão.
                
                 
                
                SUA CURVATURA C FICARÁ RÍGIDA, DO JEITINHO QUE VOCÊ FEZ, APÓS SECAR NA CABINE!
                
                 
                
                Pode ser utilizado em qualquer técnica de alongamento, já que é um gel de construção. 
                
                 
                
                Assim como todos os géis da linha MUY BIELA, é auto-nivelante, muito resistente, não amarela e não gera desconforto quando vai a cabine. 
                
                 
                
                Também pode ser utilizado nas manutenções, preservando a cor do alongamento de acordo com a cor do seu Gel Strong preferido.
                
                 
                
                Atualmente, a coleção contem os seguintes itens, que podem ser adquiridos individualmente:
                
                CLEAR - NATURAL CLASSIC -  NUDE - MILK
                
                Perfeito para profissionais.`,
            },
            {
                id: '42',
                name: "BASEFIX MUY BIELA 10ML",
                imagem: "https://static.wixstatic.com/media/5ae693_b519a5cd50a04fc38d55c8d29cc1c9fa~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_b519a5cd50a04fc38d55c8d29cc1c9fa~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `O BASEFIX MUY BIELA é um aderenciador de unhas artificiais a base de gel.
        
         
        
                Desenvolvido para criar maior aderência entre a unha natural e o gel de unhas artificiais (ou esmalte em gel) de forma muito mais eficaz.
                
                 
                
                PERFEITO! Base gel universal com ALTÍSSIMA ADERÊNCIA à lâmina ungueal.
                
                
                SUPER FLEXÍVEL! Cria uma ótima adesão ao gel, evitando bolhas de descolamento. 
                
                 
                
                Auxiliador de grande importância em clientes com disturbios hormonais, sudorese, tireoide e diabetes, prevenindo descolamento precoce.
                
                É compatível com a grande maioria de marcas de gel UV/SUNUV existentes no mercado brasileiro.
                
                 
                
                MODO DE USAR:
                
                Após ter feito a preparação das unhas...
                
                1 - Passar uma fina camada de BASEFIX MUY BIELA sobre as unhas sem deixar encostar na cutícula, friccionando o pincel sobre a unha.
                
                2 - Secar 2 minutos em cabine UV/SUNUV para cura total do produto.
                
                3 - Não remova a goma residual (TAC) criada após a secagem em cabine.
                
                4 - Aplicar o gel sobre o Basefix com a goma residual (TAC).
                
                5 - Seguir os demais processos do seu protocolo de aplicação, de acordo com sua técnica/trabalho que está sendo executado.`,
            },
            {
                id: '43',
                name: "CLEAN HIGIENIZANTE MUY BIELA (PREP) 120ML",
                imagem: "https://static.wixstatic.com/media/5ae693_b65a800684ce46fda4fcf76cba938b9e~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_b65a800684ce46fda4fcf76cba938b9e~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `A maneira mais eficaz de higienizar suas unhas.
        
                Atua também como higienizante para as mãos, pois tem em sua formulação componentes altamente eficazes, proporcionando maior segurança para a cliente e também para a profissional.
                
                Utilizado também para moldar as unhas de Polymer Gel (nosso gel em pasta) durante o processo de construção e manutenção das unhas.
                
                Disponivel em embalagens de 120ml`,
            },
            {
                id: '44',
                name: "CLEAN HIGIENIZANTE MUY BIELA (PREP) 60ML",
                imagem: "https://static.wixstatic.com/media/5ae693_100fc8926b134b1981ab167eb4f8464c~mv2.jpg/v1/fill/w_980,h_551,al_c,q_85,usm_0.66_1.00_0.01/5ae693_100fc8926b134b1981ab167eb4f8464c~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `A maneira mais eficaz de higienizar suas unhas.
        
                Atua também como higienizante para as mãos, pois tem em sua formulação componentes altamente eficazes, proporcionando maior segurança para a cliente e também para a profissional.
                
                Utilizado também para moldar as unhas de Polymer Gel (nosso gel em pasta) durante o processo de construção e manutenção das unhas.
                
                Disponivel em embalagens de 60ml.`,
            },
            {
                id: '45',
                name: "GEL MUY BIELA ACRILICO 24G",
                imagem: "https://static.wixstatic.com/media/5ae693_7d95befa69574699ab61b59d806d4d9a~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_7d95befa69574699ab61b59d806d4d9a~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Assim como todos os géis da linha MUY BIELA, é autonivelante, muito resistente, não amarela e não gera desconforto quando vai a cabine. 
        
         
        
                Também pode ser utilizado nas manutenções que não necessitam ter cor de fundo, ou seja, unhas totalmente transparentes, como um acrílico.
                
                É altamente utilizado pelas profissionais que fazem trabalhos artísticos durante o processo de construção da unha artificial, como por exemplo, encapsulamentos de glitter, flores, folhas e tudo o que a criatividade imaginar.
                
                 
                
                Nas construções de unhas conhecidas como Francesa Reversa, este produto é utilizado como base inicial, dando maior beleza ao seu trabalho quando se olha a unha por baixo.
                
                 
                
                Perfeito para profissionais.`,
            },
            {
                id: '46',
                name: "GEL MUY BIELA VITRAL VERMELHO 15G",
                imagem: "https://static.wixstatic.com/media/5ae693_2488217e8b724aa891e51d4db620df4d~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_2488217e8b724aa891e51d4db620df4d~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `Por ser um gel colorido e sem cor de fundo (transparente) o GEL MUY BIELA VITRAL VERMELHO é um gel versátil, com múltiplas funções.
        
         
        
                Pode ser utilizado em qualquer técnica de alongamento, já que é um gel de construção. 
                
                 
                
                Assim como todos os géis da linha MUY BIELA, é autonivelante, resistente, não amarela e não gera desconforto quando vai a cabine. 
                
                 
                
                É altamente utilizado pelas profissionais que fazem trabalhos artísticos durante o processo de construção da unha artificial, como por exemplo, encapsulamentos de glitter, flores, folhas e tudo o que a criatividade imaginar.
                
                 
                
                Nas construções de unhas conhecidas como Francesa Reversa, este produto é utilizado para imitar a borda livre, dando maior beleza ao resultado final do seu trabalho.
                
                 
                
                Perfeito para profissionais.`,
            },
            {
                id: '47',
                name: "OLEO DE CUTICULAS MUY BIELA 10ML",
                imagem: "https://static.wixstatic.com/media/5ae693_86e8a3beb7a142d1aa2ae662acae6849~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_86e8a3beb7a142d1aa2ae662acae6849~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `O OLEO DE CUTICULAS MUY BIELA é ideal para profissionais de alongamentos que desejam finalizar as unhas artificias mostrando o resultado que as clientes mecerem.
        
         
        
                Proporciona hidratação de alta qualidade na região das cutículas após o procedimento de aplicação, manutenção ou remoção das unhas artificiais.
                
                 
                
                FRAGRANCIA SUPER AGRADÁVEL. Levemente perfumado.`,
            },
            {
                id: '48',
                name: "PRIMER MUY BIELA 10ML",
                imagem: "https://static.wixstatic.com/media/5ae693_0c9219878f13497d9186cbb4d2d15c30~mv2.png/v1/fill/w_630,h_354,al_c,q_90/5ae693_0c9219878f13497d9186cbb4d2d15c30~mv2.webp",
                howtouse: 'teste',
                services: 'teste',
                benefits: `O PRIMER MUY BIELA é um desidratador e preparador acido para unhas artificiais a base de gel.
        
         
        
                Desenvolvido para criar máxima aderência entre a unha natural e o gel de unhas artificiais (ou esmalte em gel) de forma muito mais eficaz.
                
                 
                
                O MAIS EFICIENTE! O Primer Muy Biela ácido é um super desidratador que proporciona ALTÍSSIMA ADERÊNCIA do gel à lâmina ungueal.
                
                 
                
                Não necessita de cabine UV.
                
                 
                
                Produto para profissionais.`,
            }
        ]
    }

]

export default Produtos