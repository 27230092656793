import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import "./styles.css";

import Logo1 from "../../assets/logopt1.png"
import Logo2 from "../../assets/logopt2.png"

function PageHeader() {
    return (
        <header className='page-header'>
            <Link to="/">
            <img src={Logo1} alt=""/>
            <img src={Logo2} alt=""/> 
            </Link>

            <nav>
                <Link style={{ textDecoration: 'none' }} to="/"><h3>Início</h3></Link>
                <HashLink style={{ textDecoration: 'none' }} to="/#quemsomos"><h3>Quem Somos</h3></HashLink>
                <a target="_blank" rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=551532221110"><h3>Fale Conosco</h3></a>
            </nav>
        </header>
    )
}

export default PageHeader