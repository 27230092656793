import React from 'react';

import Routes from './routes'

import './assets/styles/global.css'

function App() {
  return (
    <Routes />
  );
}

export default App;
//use componentes em vez de usar banco de dados